import { WalkSceneActiveElement } from "@/modes/walk-mode/walk-types";
import { RootState } from "@/store/store";
import {
  IElement,
  IElementGenericAnnotation,
  IElementType,
  IElementTypeHint,
  IElementWithUri,
  isIElemLinkCommand,
  isIElementGenericStream,
  isIElementImageCommand,
  isIElementImg360,
  isIElementMarkup,
  isIElementMarkupCommand,
  isIElementPdfCommand,
  isIElementUrlLinkCommand,
} from "@faro-lotv/ielement-types";
import { selectIElementChildren } from "@faro-lotv/project-source";
import { Material, Matrix4, Object3D } from "three";

/** Specifies the type of the annotation tool/s available in the given scene */
export enum AvailableAnnotationTools {
  /** Only rectangle annotation tool is available */
  rectangle = "rectangle",

  /** Only single point annotation tool is available */
  singlePoint = "singlePoint",

  /** All types of annotation tools are available */
  all = "all",
}

export enum AlignAt {
  /** Align at the center of the object */
  center = "center",

  /** Align at the given anchor point */
  anchorPoint = "anchorPoint",

  /** Align at the top-left of the object's bounding box */
  boundingBoxTopLeft = "boundingBoxTopLeft",

  /** Align at the top-right of the object's bounding box */
  boundingBoxTopRight = "boundingBoxTopRight",
}

export type AnnotationProps = {
  /** The annotation iElement to render */
  iElement: IElementGenericAnnotation;

  /** The world offset applied to the pano associated to the annotation */
  worldTransform: Matrix4;

  /** Whether depth testing should be used to render the annotation */
  depthTest?: Material["depthTest"];

  /** The render order to use for the annotation */
  renderOrder?: Object3D["renderOrder"];

  /** Callback executed when the active element should be changed */
  onTargetElementChanged?(element: WalkSceneActiveElement): void;
};

/**
 * @returns True if the iElement is a valid target element for walk mode
 * @param e The element to check
 */
export function isIElementWalkActiveElement(
  e: IElement,
): e is WalkSceneActiveElement {
  return isIElementImg360(e) || isIElementGenericStream(e);
}

/**
 * @returns True if the iElement is an annotation containing links to external resources
 * @param e The element to check
 */
export function isIElementLinkToExternal(e: IElement): e is IElementWithUri {
  return (
    e.type === IElementType.urlLink ||
    e.type === IElementType.pdfAttachment ||
    e.type === IElementType.audioAttachment ||
    (e.type === IElementType.img2d && e.typeHint === IElementTypeHint.command)
  );
}

/**
 * @param iElement to check
 * @returns true if the iElement has a children that is a IElementMarkupCommand
 */
function selectIsInfoAnnotation(iElement: IElement) {
  return (state: RootState): boolean => {
    if (iElement.type !== IElementType.img2d) return false;
    const children = selectIElementChildren(iElement.id)(state);
    return children.some(isIElementMarkupCommand);
  };
}

/**
 * @param iElement to check
 * @returns true if the iElement has a children that is a IElementImageCommand
 */
function selectIsImageAnnotation(iElement: IElement) {
  return (state: RootState): boolean => {
    const children = selectIElementChildren(iElement.id)(state);
    return children.some(isIElementImageCommand);
  };
}

/**
 * @param iElement to check
 * @returns true if the iElement has a children that is a IElemLinkCommand, IElementUrlLinkCommand or a IElementPdfCommand
 */
function selectIsGeneralLinkAnnotation(iElement: IElement) {
  return (state: RootState): boolean => {
    if (iElement.type !== IElementType.img2d) return false;
    const children = selectIElementChildren(iElement.id)(state);
    return children.some(
      (child) =>
        isIElemLinkCommand(child) ||
        isIElementUrlLinkCommand(child) ||
        isIElementPdfCommand(child),
    );
  };
}

/**
 * @param iElement to check
 * @returns true if the IElement is a supported IElement type with markup
 */
function selectIsMarkupAnnotation(iElement: IElement) {
  return (state: RootState): boolean =>
    selectIElementChildren(iElement.id)(state).some(isIElementMarkup);
}

// Annotation types that are not part of this enum will not be rendered
export enum AnnotationTypes {
  info = "Info",
  image = "Image",
  generalLink = "GeneralLink",
  markup = "Markup",
}

/**
 * @param iElement to check
 * @returns the type of the annotation passed
 */
export function selectAnnotationType(iElement: IElementGenericAnnotation) {
  return (state: RootState): AnnotationTypes | undefined => {
    if (selectIsInfoAnnotation(iElement)(state)) {
      return AnnotationTypes.info;
    } else if (selectIsImageAnnotation(iElement)(state)) {
      return AnnotationTypes.image;
    } else if (selectIsGeneralLinkAnnotation(iElement)(state)) {
      return AnnotationTypes.generalLink;
    } else if (selectIsMarkupAnnotation(iElement)(state)) {
      return AnnotationTypes.markup;
    }
  };
}
