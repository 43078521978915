import { GUID } from "@faro-lotv/ielement-types";
import { RootState } from "./store";

/**
 * @returns the list of SendTo points associated to an element
 * @param id The id of the element which SendTo points we want to get
 */
export function selectSendToPoints(id?: GUID) {
  return (state: RootState) => {
    if (!id) return [];

    return id in state.sendToTool.sendToPoints
      ? state.sendToTool.sendToPoints[id]
      : [];
  };
}
