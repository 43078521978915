import {
  AnnotationVisibility,
  computeAnnotationVisibility,
} from "@/components/r3f/renderers/annotations/annotation-utils";
import { Measurement } from "@/store/measurement-tool-slice";
import { SupportedCamera } from "@faro-lotv/lotv";
import { Camera } from "three";

/**
 * This class provides an algorithm to compute the visibility flags of a list of
 * two-point measurements, provided a rendering camera. Measures behind the camera are
 * not visible. The active measure is always visible with its components. The other measures
 * are visible without components if their distance to the camera is lower than a given threshold.
 * They are visible as icons if they are farther.
 *
 * TODO: add tests when the behavior is stable. https://faro01.atlassian.net/browse/SWEB-1627
 */
export class MeasuresSorter {
  /** Seconds after which the object becomes 'dirty' and therefore a React state update is needed by the client component. */
  secsBeforeUpdate = 0.1;
  /** Resulting visibility flags, one for each measurement in the scene */
  measuresVisibility = new Array<AnnotationVisibility>();

  #elapsedTime = 0;
  #dirty = false;
  #activeId: string | undefined = undefined;

  /**
   *
   * @param camera the camera rendering the measures
   */
  constructor(private camera: Camera) {}

  /**
   * Updates the visibility flags of the given list of measurements.
   *
   * @param camera The camera used to render the scene
   * @param measurements The measurements to be displayed
   * @param activeMeasurementId UUID of the active measurement, or undefined if there is no active measurement
   * @param delta Delta time since last update, in seconds
   * @param fadeThreshold The distance threshold after which annotations start fading (in meters)
   * @param hideThreshold The distance threshold over which annotations are hidden (in meters)
   */
  update(
    camera: SupportedCamera,
    measurements: Measurement[],
    activeMeasurementId: string | undefined,
    delta: number,
    fadeThreshold?: number,
    hideThreshold?: number,
  ): void {
    if (this.measuresVisibility.length !== measurements.length) {
      this.setDirty();
      this.measuresVisibility.length = measurements.length;
    }
    if (this.#activeId !== activeMeasurementId) {
      this.setDirty();
      this.#activeId = activeMeasurementId;
    }

    for (let i = 0; i < measurements.length; ++i) {
      const m = measurements[i];
      if (m.id === activeMeasurementId) {
        this.measuresVisibility[i] = AnnotationVisibility.Visible;
      } else {
        this.measuresVisibility[i] = computeAnnotationVisibility(
          camera,
          m.points,
          undefined,
          fadeThreshold,
          hideThreshold,
        ).visibility;
      }
    }
    this.#elapsedTime += delta;
    while (this.#elapsedTime > this.secsBeforeUpdate) {
      this.#elapsedTime -= this.secsBeforeUpdate;
      this.setDirty();
    }
  }

  /** Sets the dirty flag, meaning that the measurements' visibility flags should be updated. */
  setDirty(): void {
    this.#dirty = true;
  }

  /** Lowers the dirty flag, meaning that there is no need to update the visibility flags. */
  resetDirty(): void {
    this.#dirty = false;
  }

  /** @returns the dirty flag. */
  dirty(): boolean {
    return this.#dirty;
  }
}
