import { FaroButton, FaroTooltip } from "@faro-lotv/flat-ui";
import { RegistrationState } from "@faro-lotv/service-wires";

interface ToggleEditRegistrationButtonProps {
  /** The currently active revision state. Used to determine whether editing is possible. */
  revisionState: RegistrationState;

  /** Whether registration editing is currently enabled */
  isEditRegistrationEnabled: boolean;

  /** A callback to execute when the user toggles the registration editing */
  onToggleEditRegistration(): void;
}

/** @returns a button to toggle the visual registration mode */
export function ToggleEditRegistrationButton({
  revisionState,
  isEditRegistrationEnabled: isVisualRegistrationEnabled,
  onToggleEditRegistration: onToggleVisualRegistration,
}: ToggleEditRegistrationButtonProps): JSX.Element {
  const shouldDisable = !isEditAllowedInState(revisionState);

  return (
    <FaroTooltip
      title={
        shouldDisable
          ? "Cannot edit while a registration is in progress."
          : undefined
      }
    >
      <FaroButton
        variant="secondary"
        onClick={onToggleVisualRegistration}
        disabled={shouldDisable}
        fullWidth
      >
        {isVisualRegistrationEnabled
          ? "Discard registration edit"
          : "Edit registration"}
      </FaroButton>
    </FaroTooltip>
  );
}

/**
 * @returns whether editing of a revision is possible in its current state
 * @param revisionState the state of the revision to check
 */
function isEditAllowedInState(revisionState: RegistrationState): boolean {
  // Using a switch to check if all states are covered
  switch (revisionState) {
    case RegistrationState.started:
    case RegistrationState.userModified:
    case RegistrationState.registered:
      return true;
    case RegistrationState.canceled:
    case RegistrationState.cloudRegistrationStarted:
    case RegistrationState.merged:
      return false;
  }
}
