import { HorizontalResizeContainer } from "@/components/ui/resize-container";
import { MultiRegistrationReport } from "@/registration-tools/utils/multi-registration-report";
import { useAppSelector } from "@/store/store-hooks";
import { FaroText } from "@faro-lotv/flat-ui";
import { MaybeLoading } from "@faro-lotv/foundation";
import { GUID } from "@faro-lotv/ielement-types";
import { selectDashboardUrl } from "@faro-lotv/project-source";
import { RegistrationRevision } from "@faro-lotv/service-wires";
import { Stack } from "@mui/material";
import { ReactNode } from "react";
import { DataPreparationStepKey } from "../data-preparation-stepper";
import { ConfirmAndRunRefinementButton } from "../inspect-and-publish/inspect-and-publish-confirm-refinement-button";
import { InspectAndPublishSidebarButtons } from "../inspect-and-publish/inspect-and-publish-sidebar-buttons";
import { ToggleEditRegistrationButton } from "../inspect-and-publish/toggle-edit-registration-button";
import { selectRevisionEntities } from "../store/revision-selectors";
import { DataPrepParametersMenu } from "./parameters-menu/data-prep-parameters-menu";
import { ScanTree } from "./scan-tree/scan-tree";

type DataPreparationSidebarProps = {
  /** currently active step in the data-preparation page */
  activeStepKey: DataPreparationStepKey;

  /** The revision to publish in the publish step */
  revision: RegistrationRevision;

  /** The registration report for the active revision. */
  registrationReport: MaybeLoading<MultiRegistrationReport>;

  /** Whether the report is currently open */
  isReportOpen: boolean;

  /** Callback, when the report should be opened/closed */
  onToggleReport(shouldOpen: boolean): void;

  /** The project id to redirect to after confirming and running the refinement. */
  projectId?: GUID;

  /** Whether registration editing is currently enabled */
  isEditRegistrationEnabled: boolean;

  /** A callback to execute when the user toggles the visual registration */
  onToggleEditRegistration(): void;
};

/** @returns Sidebar to use throughout the data preparation tool. */
export function DataPreparationSidebar({
  activeStepKey,
  revision,
  registrationReport,
  isReportOpen,
  onToggleReport,
  projectId,
  isEditRegistrationEnabled,
  onToggleEditRegistration,
}: DataPreparationSidebarProps): JSX.Element {
  const dashboardUrl = useAppSelector(selectDashboardUrl);
  return (
    <ScanTreeSidebar
      title={
        activeStepKey === DataPreparationStepKey.registration
          ? "Uploaded data"
          : "Registered scans"
      }
      description={
        activeStepKey === DataPreparationStepKey.registration
          ? "Register uploaded scans."
          : "Publish the registration result as a merged project point to your SphereXG project."
      }
      buttons={
        activeStepKey === DataPreparationStepKey.inspectAndPublish ? (
          <InspectAndPublishSidebarButtons
            revision={revision}
            registrationReport={registrationReport}
            isReportOpen={isReportOpen}
            onToggleReport={onToggleReport}
          />
        ) : (
          <>
            <ToggleEditRegistrationButton
              revisionState={revision.state}
              isEditRegistrationEnabled={isEditRegistrationEnabled}
              onToggleEditRegistration={onToggleEditRegistration}
            />
            <Stack direction="row">
              <DataPrepParametersMenu />
              <ConfirmAndRunRefinementButton
                revisionState={revision.state}
                projectId={projectId}
                dashboardUrl={dashboardUrl}
              />
            </Stack>
          </>
        )
      }
    />
  );
}

type ScanTreeSidebarProps = {
  /** The title of the current step. */
  title: ReactNode;

  /** The description of the current step. */
  description: ReactNode;

  /** The actions that can be used in the data preparation step. */
  buttons?: ReactNode;
};

/** @returns a sidebar with containing a ScanTree with customizable title and action buttons */
function ScanTreeSidebar({
  title,
  description,
  buttons,
}: ScanTreeSidebarProps): JSX.Element {
  const entities = useAppSelector(selectRevisionEntities);

  return (
    <HorizontalResizeContainer
      initialWidth={320}
      minWidth={200}
      maxWidth={500}
      handleSide="right"
    >
      <Stack
        justifyContent="space-between"
        flexShrink={0}
        sx={{
          width: "100%",
          height: "100%",
          px: 1.5,
          py: 3,
        }}
      >
        <Stack gap={2} height="100%">
          <Stack gap={1.5}>
            <FaroText variant="heading16">{title}</FaroText>
            <FaroText variant="bodyM">{description}</FaroText>
          </Stack>

          <ScanTree entities={entities} />
        </Stack>

        {/* Buttons */}
        <Stack gap={1}>{buttons}</Stack>
      </Stack>
    </HorizontalResizeContainer>
  );
}
