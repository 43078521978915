import { Point3 } from "@faro-asbuilt/send-to-plugin";
import { createContext, useContext } from "react";

/** Context providing sendToRevit instance */
export type SendToRevitContext = {
  /** send given point to Revit */
  sendPoint?(id: string, point: Point3, cameraPosition: Point3): void;

  /** send a generic command string to Revit */
  sendCommand?(command: string): void;

  /** establish connection to Revit */
  connect(): void;

  /** close connection to Revit */
  disconnect(): void;

  /** is Revit connection established */
  isConnected: boolean;
};

/**
 * The SendToRevit context
 */
export const SendToRevitContext = createContext<SendToRevitContext | undefined>(
  undefined,
);

/**
 * @returns the current SendToContext or error if not available
 */
export function useSendToRevitContext(): SendToRevitContext {
  const context = useContext(SendToRevitContext);

  if (!context) {
    throw Error(
      "useSendToRevitContext() must be used within a SendToRevitContext",
    );
  }

  return context;
}
