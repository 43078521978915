import { RootState } from "@/store/store";
import {
  NdrParams,
  Open3dIcpParams,
  RegistrationAlgorithm,
  TvbParams,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";

/**
 * @returns The stored registration algorithm
 * @param root the current application state
 */
export function selectRegistrationAlgorithm(
  root: RootState,
): RegistrationAlgorithm | undefined {
  return root.registrationParameters.registrationAlgorithm;
}

/**
 * @param root The current application state.
 * @returns The current settings for the registration algorithm.
 */
export const selectRegistrationAlgorithmSettings = createSelector(
  [
    selectRegistrationAlgorithm,
    selectIcpParams,
    selectNdrParams,
    selectTvbParams,
  ],
  (registrationAlgorithm, icpParams, ndrParams, tvbParams) => {
    const algorithm = registrationAlgorithm ?? RegistrationAlgorithm.Open3dIcp;

    switch (algorithm) {
      case RegistrationAlgorithm.Open3dIcp:
        return {
          algorithm,
          parameters: icpParams,
        };
      case RegistrationAlgorithm.Ndr:
        return {
          algorithm,
          parameters: ndrParams,
        };
      case RegistrationAlgorithm.Tvb:
        return {
          algorithm,
          parameters: tvbParams,
        };
    }
  },
);

/**
 * @returns The stored tvb parameter for the NDR parameter set
 * @param root the current application state
 */
export function selectNdrTvb(root: RootState): boolean | undefined {
  return root.registrationParameters.ndrParams.tvb;
}

/**
 * @returns The stored voxel size for the NDR parameter set
 * @param root the current application state
 */
export function selectNdrVoxelSize(root: RootState): number | undefined {
  return root.registrationParameters.ndrParams.voxel_size;
}

/**
 * @returns The stored number of max iterations for the ICP parameter set
 * @param root the current application state
 */
export function selectIcpMaxIterations(root: RootState): number | undefined {
  return root.registrationParameters.icpParams.max_iterations;
}

/**
 * @returns The stored voxel size for the ICP parameter set
 * @param root the current application state
 */
export function selectIcpVoxelSize(root: RootState): number | undefined {
  return root.registrationParameters.icpParams.voxel_size;
}
/**
 * @returns The stored NDR parameter set
 * @param root the current application state
 */
export function selectNdrParams(root: RootState): NdrParams {
  return root.registrationParameters.ndrParams;
}

/**
 * @returns The stored ICP parameter set
 * @param root the current application state
 */
export function selectIcpParams(root: RootState): Open3dIcpParams {
  return root.registrationParameters.icpParams;
}

/**
 * @returns The stored TVB parameter set
 * @param root the current application state
 */
export function selectTvbParams(root: RootState): TvbParams {
  return root.registrationParameters.tvbParams;
}
