import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { useThreeEventTarget } from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { useTheme } from "@mui/material";
import { Vector3 as Vector3Prop } from "@react-three/fiber";
import { HandlerRenderer } from "./measurements/measure-handler";

// Sizes for the SendTo handlers.
// The handlers change size based on if the SendTo tool is active or not.
const SMALL_HANDLER_SIZE = 8;
const BIG_HANDLER_SIZE = 12;

/** Props of SendToPointRenderer component */
export type SendToPointRendererProps = {
  /** The SendTo point, in world coordinates */
  point: Vector3Prop;
};

/**
 * @returns A R3F component to render a point
 */
export function SendToPointRenderer({
  point,
}: SendToPointRendererProps): JSX.Element | null {
  const viewOverlay = useThreeEventTarget();
  assert(
    // Return of useThreeEventTarget is not correctly typed (see https://faro01.atlassian.net/browse/SWEB-3461)
    // eslint-disable-next-line @typescript-eslint/no-unnecessary-condition
    viewOverlay !== null,
    "Cannot create a AnnotationRenderer component because R3F canvas has no parent HTML element.",
  );

  const theme = useTheme();

  // Check if the SendTo tool is active
  const sendToActive = useAppSelector(selectActiveTool) === ToolName.sendTo;

  return (
    <HandlerRenderer
      position={point}
      size={sendToActive ? BIG_HANDLER_SIZE : SMALL_HANDLER_SIZE}
      color={sendToActive ? theme.palette.primary.main : theme.palette.white}
    />
  );
}
