import { selectCadLevelsInWorldCs } from "@/store/cad/cad-slice";
import { selectCloudToCadAlignmentModelElevation } from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import { setModelElevationForCloudAlignment } from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { Dropdown, TextField, neutral } from "@faro-lotv/flat-ui";
import { SelectChangeEvent, Stack } from "@mui/material";
import { useCallback, useEffect, useRef, useState } from "react";

/**
 * @returns cloud to cad alignment model elevation control panel
 */
export function ModelElevationTool(): JSX.Element {
  const dispatch = useAppDispatch();

  const modelElevation = useAppSelector(
    selectCloudToCadAlignmentModelElevation,
  );

  const cadLevelsDescription = useAppSelector(selectCadLevelsInWorldCs);
  const cadLevelsOptions = cadLevelsDescription?.map((level) => ({
    key: level.Name,
    value: level.Elevation.toString(),
    label: level.Name,
  }));

  // Define the state variables for the levels dropdown
  const [levelsDropdownSelection, setLevelsDropdownSelection] = useState("");

  // Define the state variables for the text field
  const [elevationErrorText, setElevationErrorText] = useState("");
  const [elevationTextField, setElevationTextField] = useState(
    modelElevation.toFixed(3).toString(),
  );

  // variable to keep track of whether the level dropdowns has been changed by the user
  const levelsDropdownChanged = useRef(false);

  // variable to keep track of whether the text field has been changed by the user
  const elevationTextFieldChanged = useRef(false);

  // Update the UI when the sheet elevation changes
  useEffect(() => {
    if (!levelsDropdownChanged.current) {
      setLevelsDropdownSelection("");
    }

    if (!elevationTextFieldChanged.current) {
      setElevationTextField(modelElevation.toFixed(3));
      setElevationErrorText("");
    }

    levelsDropdownChanged.current = false;
    elevationTextFieldChanged.current = false;
  }, [modelElevation]);

  const onTextChanged = useCallback(
    (newText: string) => {
      elevationTextFieldChanged.current = true;
      setElevationTextField(newText);
      const newElevation = Number(newText);
      if (isNaN(newElevation)) {
        setElevationErrorText("Elevation must be a number");
        elevationTextFieldChanged.current = false;
      } else {
        setElevationErrorText("");
        if (modelElevation !== newElevation) {
          dispatch(setModelElevationForCloudAlignment(newElevation));
        }
      }
    },
    [dispatch, modelElevation],
  );

  const onSelectChanged = useCallback(
    (event: SelectChangeEvent<string>) => {
      levelsDropdownChanged.current = true;
      const newHeight = parseFloat(event.target.value);
      setLevelsDropdownSelection(event.target.value);
      dispatch(setModelElevationForCloudAlignment(newHeight));
    },
    [dispatch],
  );

  return (
    <Stack
      direction="column"
      spacing={2}
      sx={{
        borderRadius: "5px",
        borderTop: "1px",
        borderBottom: "1px",
        borderLeft: "1px",
        borderLeftColor: neutral[999],
        borderLeftStyle: "solid",
        borderRight: "1px",
        borderRightColor: neutral[999],
        borderRightStyle: "solid",
        px: 1,
        pt: 1,
        backgroundColor: neutral[999],
        position: "absolute",
        top: 75,
        height: "auto",
        right: 18,
        width: "auto",
      }}
    >
      <Dropdown
        label="Level"
        options={
          cadLevelsOptions ?? [{ key: "N/A", value: "N/A", label: "N/A" }]
        }
        disabled={!cadLevelsOptions}
        sx={{
          height: "30px",
        }}
        dark
        value={cadLevelsOptions?.length ? levelsDropdownSelection : "N/A"}
        onChange={onSelectChanged}
      />

      <TextField
        fullWidth
        label="Elevation (m)"
        dark
        sx={{ marginBottom: "8px", height: "30px" }}
        text={elevationTextField}
        error={elevationErrorText}
        onTextChanged={onTextChanged}
      />
    </Stack>
  );
}
