import { useAppDispatch } from "@/store/store-hooks";
import {
  LENS_CENTER,
  LensActive,
  LensDefault,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import {
  RefObject,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useMemo,
  useState,
} from "react";
import { MOUSE, Vector2, Vector3 } from "three";
import { ToolControlsRef } from "../tool-controls-interface";
import {
  MultiPointMeasureControlsLogic,
  ProjectionType,
} from "./multi-point-measures-controls-logic";

/** The distance in pixels between the two coordinates within which a click can place a point on the model */
const VALID_CLICK_DISTANCE = 50;

/** A reusable vector2 to store pointer coordinates on pointerdown */
const POINTER_DOWN_COORDINATES = new Vector2(0, 0);

export type MultiPointMeasureControlsActions = {
  /**
   * Complete the current interaction
   *
   * @param isClosed Flag specifying if the measurement is a closed polygon
   */
  completeMeasurement(isClosed: boolean): void;

  /**
   * Delete a point from the current polygon
   *
   * @param index The index of the point to remove
   */
  deletePoint(index: number): void;
};

type MultiPointMeasureControlsProps = {
  /** Callback issued when a picked point changes */
  onCurrentPointChanged(point: Vector3 | undefined): void;
  /** Callback issued when both points are defined */
  onMeasurementCompleted(isClosed: boolean, id: string): void;
  /** Callback issued when the currently selected measurement should be deleted */
  onDeleteActiveMeasurement(): void;
  /** Callback issued when a measurement is initiated */
  onMeasurementStarted?(): void;
  /** Callback issued when the user presses ESC key */
  onEscPressed?(): void;
  /** Callback issued when the array of points changes */
  onPointsChanged?(points: Vector3[] | undefined): void;
  /** Callback issued when the measurement is canceled */
  onMeasurementCanceled?(): void;
  /** The actions that can be called from outside the controls */
  actions?: RefObject<MultiPointMeasureControlsActions>;
  /** Whether the control is active or not */
  active: boolean;
  /** type of projection to apply when the shift key is pressed */
  projectOnShiftKey: ProjectionType;
};

export const MultiPointMeasureControls = forwardRef<
  ToolControlsRef,
  MultiPointMeasureControlsProps
>(function MultiPointMeasureControls(
  {
    onCurrentPointChanged,
    onMeasurementCompleted,
    onDeleteActiveMeasurement,
    onMeasurementStarted,
    onEscPressed,
    onPointsChanged,
    onMeasurementCanceled,
    actions,
    active,
    projectOnShiftKey,
  }: MultiPointMeasureControlsProps,
  ref,
): JSX.Element {
  const domElement = useThreeEventTarget();
  const [initialCursor] = useState(domElement.style.cursor);
  const dispatch = useAppDispatch();

  // Creating the controls logic
  const controls = useMemo(() => {
    return new MultiPointMeasureControlsLogic();
  }, []);

  const completeMeasurement = useCallback(
    (isClosed: boolean) => {
      controls.complete(isClosed);
    },
    [controls],
  );

  const deletePoint = useCallback(
    (index: number) => {
      controls.deletePoint(index, true);
    },
    [controls],
  );

  useImperativeHandle(actions, () => ({ completeMeasurement, deletePoint }));

  // Attach all callbacks
  useEffect(() => {
    const disp = controls.onMeasurementStarted.on(() =>
      onMeasurementStarted?.(),
    );
    return () => {
      disp.dispose();
    };
  }, [controls, onMeasurementStarted]);

  useEffect(() => {
    const disp = controls.onCurrentPointChanged.on((point) => {
      onCurrentPointChanged(point);
    });
    return () => {
      disp.dispose();
    };
  }, [controls, onCurrentPointChanged]);

  useEffect(() => {
    if (!onPointsChanged) return;
    const disp = controls.onPointsChanged.on(() => {
      onPointsChanged(controls.points);
    });
    return () => {
      disp.dispose();
    };
  }, [controls, onCurrentPointChanged, onPointsChanged]);

  useEffect(() => {
    const disp = controls.onMeasurementCompleted.on(({ isClosed, id }) => {
      onMeasurementCompleted(isClosed, id);
    });
    return () => {
      disp.dispose();
    };
  }, [controls, onMeasurementCompleted]);

  useEffect(() => {
    if (!onMeasurementCanceled) return;
    const disp = controls.onMeasurementCanceled.on(() => {
      onMeasurementCanceled();
    });
    return () => {
      disp.dispose();
    };
  }, [controls, onMeasurementCanceled]);

  // Bind the escape key to cancel the current measurement
  // And the Delete key to delete the active measurement
  // Making sure to unbind on unmount or when controls change, before binding to the new controls.
  useEffect(() => {
    const handleKeyDown = (event: KeyboardEvent): void => {
      switch (event.key) {
        case "Escape":
          if (!controls.points || controls.points.length <= 1) {
            // delete last picked point if any...
            if (!controls.deleteLatestPoint()) {
              // ...or cancel the measurement if no points are picked
              controls.cancel();
            }
          } else {
            // for 2 or more picked points, Escape key completes the measurement as open polygon
            controls.complete(false);
          }
          break;
        case "Delete":
          if (!controls.isLiveMeasure()) {
            // Delete key does delete the active measurement when not creating a new measurement
            onDeleteActiveMeasurement();
          }
          break;
        case "Backspace":
          if (controls.isLiveMeasure()) {
            // Backspace key does delete the latest picked point when creating a new measurement
            controls.deleteLatestPoint();
          }
          break;
        case "Shift":
          controls.enableProjection(projectOnShiftKey);
          break;
      }
    };
    const handleKeyUp = (event: KeyboardEvent): void => {
      switch (event.key) {
        case "Shift":
          controls.enableProjection(ProjectionType.DoNotProject);
          break;
      }
    };
    window.addEventListener("keydown", handleKeyDown);
    window.addEventListener("keyup", handleKeyUp);

    return () => {
      window.removeEventListener("keydown", handleKeyDown);
      window.removeEventListener("keyup", handleKeyUp);
    };
  }, [
    controls,
    onDeleteActiveMeasurement,
    onEscPressed,
    dispatch,
    projectOnShiftKey,
  ]);

  useEffect(() => {
    // initialize the tool activated
    controls.initialize();
  }, [active, controls]);

  /**
   * Effect to handle the changes to cursor style when the measurement tool is active and on point clicked
   */
  useEffect(() => {
    function changeToMeasureCursor(isMeasurementStarted?: boolean): void {
      domElement.style.cursor = `url("${
        isMeasurementStarted ? LensActive : LensDefault
      }") ${LENS_CENTER} ${LENS_CENTER},auto`;
    }

    function onPointerDown(ev: PointerEvent): void {
      if (ev.button === MOUSE.LEFT && !!controls.points) {
        const { pointCoordinates } = controls;
        // Distance between the currently selected point of the model and the current cursor position
        const distance = pointCoordinates.distanceTo(
          new Vector2(ev.clientX, ev.clientY),
        );

        if (distance > VALID_CLICK_DISTANCE) return;

        // The click has been executed very close to the selected point, hence proceed to click procedure
        changeToMeasureCursor(true);
        POINTER_DOWN_COORDINATES.set(ev.clientX, ev.clientY);
      }
    }
    function onClick(ev: MouseEvent): void {
      const hasDragged =
        POINTER_DOWN_COORDINATES.distanceTo(
          new Vector2(ev.clientX, ev.clientY),
        ) > 10;

      if (hasDragged) return;

      changeToMeasureCursor();
    }

    if (active) {
      changeToMeasureCursor();
      domElement.addEventListener("pointerdown", onPointerDown);
      domElement.addEventListener("click", onClick);
    } else {
      domElement.style.cursor = initialCursor;
    }

    return () => {
      domElement.removeEventListener("pointerdown", onPointerDown);
      domElement.removeEventListener("click", onClick);
      domElement.style.cursor = initialCursor;
    };
  }, [controls, domElement, initialCursor, active, onMeasurementStarted]);

  return <primitive ref={ref} object={controls} />;
});
