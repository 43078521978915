import { curryAppSelector } from "@/store/reselect";
import { RootState } from "@/store/store";
import { EMPTY_ARRAY, assert } from "@faro-lotv/foundation";
import {
  GUID,
  isIElementGenericPointCloudStream,
  isValid,
} from "@faro-lotv/ielement-types";
import {
  CachedWorldTransform,
  DEFAULT_TRANSFORM,
  selectChildDepthFirst,
  selectIElement,
} from "@faro-lotv/project-source";
import {
  CaptureTreeEntityRevision,
  RevisionScanEntity,
  isRevisionScanEntity,
} from "@faro-lotv/service-wires";
import { createSelector } from "@reduxjs/toolkit";
import { EdgesMap, EntityMap } from "./revision-slice";
import { RevisionTransformCache } from "./revision-transform-cache";

/**
 * @param state The current application state.
 * @returns A map from entity ID to the entity definition.
 */
export function selectRevisionEntityMap(state: RootState): EntityMap {
  return state.revision.entityMap;
}

/**
 * @param state The current application state.
 * @returns A map from entity ID to the world transform of the entity.
 */
export function selectRevisionTransformCache(
  state: RootState,
): RevisionTransformCache {
  return state.revision.transformCache;
}

/**
 * @param state The current application state.
 * @returns The map of all captureTree loaded edges.
 */
export function selectEdgesMap(state: RootState): EdgesMap {
  return state.revision.edgesMap;
}

/**
 * @param state The current application state.
 * @returns All loaded entities in the revision.
 */
export const selectRevisionEntities = createSelector(
  [selectRevisionEntityMap],
  // Filtering here is only necessary to make TS happy, due to the index types
  (entityMap) => Object.values(entityMap).filter(isValid),
);

/**
 * @param state The current application state.
 * @returns All loaded point cloud scans in the revision.
 */
export const selectRevisionScans = createSelector(
  [selectRevisionEntities],
  (revisionEntities) => revisionEntities.filter(isRevisionScanEntity),
);

/**
 * @param _state The current application state.
 * @param entityId Id of the revision entity to get the cached world transform for.
 * @returns The cached world transform of the entity.
 */
export const selectRevisionEntityWorldTransformCache = curryAppSelector(
  createSelector(
    [
      selectRevisionTransformCache,
      (_state: RootState, entityId?: GUID) => entityId,
    ],
    (
      transformCache: RevisionTransformCache,
      entityId?: GUID,
    ): CachedWorldTransform =>
      entityId
        ? transformCache[entityId] ?? DEFAULT_TRANSFORM
        : DEFAULT_TRANSFORM,
  ),
);

/**
 * @returns whether an entities transform has been overridden directly (does not account for overrides from ancestors)
 * @param entityId the entity id to check
 */
export function selectHasEntityTransformOverride(entityId: GUID) {
  return (state: RootState) =>
    state.revision.transformOverrides[entityId] !== undefined;
}

/**
 * @returns whether at least one entity transform has been overridden
 * @param state the current application state
 */
export function selectHasSomeEntityTransformOverride(
  state: RootState,
): boolean {
  return !!Object.keys(state.revision.transformOverrides).length;
}

/**
 * @param scanEntity The scan entity to get the point cloud stream for.
 * @returns The point cloud stream corresponding to the scan entity.
 */
export function selectPointCloudStreamForScanEntity(
  scanEntity?: RevisionScanEntity,
) {
  return (state: RootState) => {
    // The scan entity of the revision has the same ID as the data set IElement in the Capture Tree
    const dataSet = selectIElement(scanEntity?.id)(state);
    return selectChildDepthFirst(
      dataSet,
      isIElementGenericPointCloudStream,
    )(state);
  };
}

/**
 * @param _state The current application state.
 * @param id The ID of the revision entity.
 * @returns The revision entity with the given ID or `undefined` if it's not loaded.
 */
export const selectRevisionEntity = curryAppSelector(
  createSelector(
    [selectRevisionEntityMap, (_state, id?: GUID) => id],
    (entityMap, id) => id && entityMap[id],
  ),
);

/**
 * @returns a cached map of entities to their children
 * @param state the current application state
 */
const selectRevisionEntityChildrenMap = createSelector(
  [(state: RootState) => state.revision.entityMap],
  (entityMap) => {
    const map: Record<GUID, CaptureTreeEntityRevision[] | undefined> = {};

    for (const entity of Object.values(entityMap)) {
      if (!entity?.parentId) continue;

      let childrenOfParent = map[entity.parentId];

      if (!childrenOfParent) {
        childrenOfParent = [];
        map[entity.parentId] = childrenOfParent;
      }

      childrenOfParent.push(entity);
    }

    return map;
  },
);

/**
 * @param id the entity id to get the children for
 * @returns the direct children for an entity
 */
export function selectRevisionEntityChildren(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] =>
    selectRevisionEntityChildrenMap(state)[id] ?? EMPTY_ARRAY;
}

/**
 * @param id of the entity to get the descendants for
 * @returns all descendants of the entity with the given ID
 */
export function selectRevisionEntityAllDescendants(id: GUID) {
  return (state: RootState): CaptureTreeEntityRevision[] => {
    const children = selectRevisionEntityChildren(id)(state);

    const found: CaptureTreeEntityRevision[] = [];

    for (const child of children) {
      found.push(child);
      found.push(...selectRevisionEntityAllDescendants(child.id)(state));
    }

    return found;
  };
}

/**
 * @param state The current application state.
 * @param id The ID of the scan entity
 * @returns The scan entity with the given ID or `undefined` if it's not loaded.
 * @throws an assertion error if the entity is not a valid scan.
 */
export const selectRevisionEntityScan = curryAppSelector(
  createSelector(
    [(state, id: GUID) => selectRevisionEntity(id)(state)],
    (revisionEntity) => {
      if (!revisionEntity) return;

      assert(isRevisionScanEntity(revisionEntity));
      return revisionEntity;
    },
  ),
);
