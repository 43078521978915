import { useFileUploaderWithPromise } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { useCurrentProjectApiClient } from "@/components/common/project-provider/project-loading-context";
import { createAnnotationFields } from "@/components/ui/annotations/annotation-fields";
import {
  AnnotationCreationData,
  isExternalAnnotationData,
} from "@/components/ui/annotations/annotation-props";
import { createAttachments } from "@/components/ui/annotations/attachment-mutations";
import { createAnnotationZip } from "@/modes/walk-mode/create-annotation-zip";
import { selectPanoAnnotationSection } from "@/store/selections-selectors";
import { RootState } from "@/store/store";
import { useAppDispatch, useAppStore } from "@/store/store-hooks";
import { selectCurrentUser } from "@/store/user-selectors";
import { selectPanoAnnotationsAdjustedPose } from "@/utils/camera-transform";
import { selectIElementWorldMatrix4 } from "@/utils/transform-conversion-parsed";
import { GUID, assert, generateGUID } from "@faro-lotv/foundation";
import {
  IElement,
  IElementGroup,
  IElementImg360,
  IElementType,
  IElementTypeHint,
  WithHint,
  createIElement,
  isIElementWithTypeAndHint,
} from "@faro-lotv/ielement-types";
import {
  IElementWithPose,
  computeReferenceSystemProperties,
  fetchProjectIElements,
  isInsideCaptureTree,
  selectAdvancedMarkupTemplateIds,
  selectChildDepthFirst,
  selectIElementProjectApiLocalPose,
  selectProjectId,
} from "@faro-lotv/project-source";
import {
  DEFAULT_NODES_GROUP_SCALE_UNIFORM,
  MutationAdd3dNode,
  createMutationAdd3dNode,
  createMutationAddLabel,
  createMutationAddMarkup,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import { useCallback } from "react";
import { Matrix4, Mesh, Vector3 } from "three";

type CreateModel3dAnnotation = (
  pano: IElementImg360,
  annotation: Mesh,
  details: AnnotationCreationData,
) => Promise<GUID>;

/** @returns a function to create a node Model3d Annotation in a project */
export function useCreateModel3dAnnotation(): CreateModel3dAnnotation {
  const projectApi = useCurrentProjectApiClient();
  const { coreApiClient } = useApiClientContext();
  const appStore = useAppStore();
  const uploadFile = useFileUploaderWithPromise();
  const dispatch = useAppDispatch();

  return useCallback<CreateModel3dAnnotation>(
    (pano, annotation, details): Promise<GUID> => {
      // The creation of external annotations is not supported yet. This will be introduced by the following issue:
      // https://faro01.atlassian.net/browse/CADBIM-784
      assert(
        !isExternalAnnotationData(details),
        "data is not a Sphere XG annotation",
      );
      const {
        title,
        assignee,
        description,
        dueDate,
        status,
        newAttachments,
        tags,
      } = details;
      async function createAnnotation(): Promise<GUID> {
        const appState = appStore.getState();
        // Pano annotations needs to be appended to the pano parent section
        const targetIElement = selectPanoAnnotationSection(pano)(appState);
        const targetOffset = new Matrix4().fromArray(
          selectPanoAnnotationsAdjustedPose(pano)(appState),
        );
        const projectId = selectProjectId(appState);
        const currentUser = selectCurrentUser(appState);

        assert(currentUser, "Expected a logged in user");
        assert(projectId, "Expected a project ID");
        assert(
          targetIElement,
          "Unable to compute the section where to place the new annotation",
        );

        const zipData = await createAnnotationZip(annotation);
        const { downloadUrl, md5 } = await uploadFile({
          file: new File([zipData], "model.zip"),
          coreApiClient,
          uploadElementType: UploadElementType.none,
          projectId,
          silent: true,
        });

        const model3dId = generateGUID();
        const markupId = generateGUID();

        const add3dNodeMutation = createModel3dMutation({
          model: annotation,
          modelId: model3dId,
          name: title,
          modelUrl: downloadUrl,
          md5Hash: md5,
          targetOffset,
          targetIElement,
          fileSize: zipData.size,
          currentUserId: currentUser.id,
          appState,
        });

        const templateIds = selectAdvancedMarkupTemplateIds(appState);
        assert(
          templateIds,
          "Expected project to have an advanced markup template",
        );

        const markupFields = createAnnotationFields({
          assignee,
          status,
          dueDate,
          ...templateIds,
          markupId,
          currentUserId: currentUser.id,
          rootId: targetIElement.rootId,
        });

        const addMarkupMutation = createMutationAddMarkup({
          id: markupId,
          templateId: templateIds.templateId,
          rootId: targetIElement.rootId,
          name: title,
          description: description ?? "",
          annotationId: model3dId,
          markupFields,
        });

        const attachmentsMutations = createAttachments(
          targetIElement.rootId,
          markupId,
          newAttachments,
        );

        const tagsMutations =
          tags?.map((tag) => createMutationAddLabel(markupId, tag.id)) ?? [];

        await projectApi.applyMutations([
          add3dNodeMutation,
          addMarkupMutation,
          ...attachmentsMutations,
          ...tagsMutations,
        ]);

        // Update the IElement tree
        await dispatch(
          fetchProjectIElements({
            fetcher: () =>
              projectApi.getAllIElements({
                // We only need to fetch the subtree starting from the targetIElement
                ancestorIds: [targetIElement.id],
              }),
          }),
        );

        return markupId;
      }

      return createAnnotation();
    },
    [appStore, coreApiClient, dispatch, projectApi, uploadFile],
  );
}

type CreateModel3dMutationArgs = {
  /** Id of the new IElement that will capture this model */
  modelId: GUID;

  /** Name of the new model */
  name: string;

  /** Target IElement that will contain the new model */
  targetIElement: IElement;

  /** Mesh of the new model */
  model: Mesh;

  /** Current user creating the new model */
  currentUserId: GUID;

  /**
   * World space offset applied to the targetIElement by the viewer compared to its iElement position (in three.js space).
   * Used to calculate the local position of the annotation.
   */
  targetOffset: Matrix4;

  /** Url of the model mesh uploaded to Sphere XG */
  modelUrl: string;

  /** Hash of the uploaded mesh file */
  md5Hash: string;

  /** Size of the uploaded mesh file */
  fileSize: number;

  /** Current application state used to query the project */
  appState: RootState;
};

function createModel3dMutation({
  modelId,
  name,
  targetIElement,
  model,
  currentUserId,
  targetOffset,
  modelUrl,
  md5Hash,
  fileSize,
  appState,
}: CreateModel3dMutationArgs): MutationAdd3dNode {
  const existingNodesGroup = selectChildDepthFirst(targetIElement, (el) =>
    isIElementWithTypeAndHint(el, IElementType.group, IElementTypeHint.nodes),
  )(appState);

  let nodesGroup: IElement;
  let nodesGroupToCreate:
    | WithHint<IElementGroup, IElementTypeHint.nodes>
    | undefined;
  let mutationTarget: GUID;
  let targetTransform: Matrix4;

  if (existingNodesGroup) {
    mutationTarget = existingNodesGroup.id;
    nodesGroup = existingNodesGroup;
    nodesGroupToCreate = undefined;
    targetTransform = selectIElementWorldMatrix4(existingNodesGroup.id)(
      appState,
    );
  } else {
    const insideCaptureTree = isInsideCaptureTree(
      targetIElement,
      appState.iElements.iElements,
    );
    mutationTarget = targetIElement.id;
    nodesGroup = nodesGroupToCreate = createIElement<
      WithHint<IElementGroup, IElementTypeHint.nodes>
    >({
      type: IElementType.group,
      typeHint: IElementTypeHint.nodes,
      xOr: false,
      rootId: targetIElement.rootId,
      name: "Annotations",
      createdBy: currentUserId,
      parentId: targetIElement.id,
      pose: {
        pos: null,
        rot: null,
        scale: {
          x: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          y: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          z: DEFAULT_NODES_GROUP_SCALE_UNIFORM,
        },
        isWorldRot: false,
        isWorldPose: false,
        isWorldScale: false,
        gps: null,
        refCoordSystemMatrix: computeReferenceSystemProperties(
          { type: IElementType.group, typeHint: IElementTypeHint.nodes },
          insideCaptureTree,
        ),
      },
    });

    // The targetTransform needs to account for the created group having an additional scale
    targetTransform = selectIElementWorldMatrix4(targetIElement.id)(appState)
      .clone()
      .scale(
        new Vector3(
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
          DEFAULT_NODES_GROUP_SCALE_UNIFORM,
        ),
      );
  }

  model.updateMatrixWorld();
  const transformLocal = targetTransform
    .clone()
    .premultiply(targetOffset)
    .invert()
    .multiply(model.matrixWorld);

  const modelElement: IElementWithPose = {
    id: modelId,
    typeHint: IElementTypeHint.node,
    type: IElementType.model3d,
    parentId: nodesGroup.id,
  };
  const { pos, rot, scale, refCoordSystemMatrix } =
    selectIElementProjectApiLocalPose(
      modelElement,
      transformLocal,
      existingNodesGroup ? [modelElement] : [modelElement, nodesGroup],
    )(appState);

  return createMutationAdd3dNode(
    mutationTarget,
    {
      uri: modelUrl,
      name,
      md5Hash,
      fileSize,
      fileName: "model.zip",
      id: modelId,
      typeHint: IElementTypeHint.node,
      type: IElementType.model3d,
      descr: null,
      thumbnailUri: null,
      pose: {
        pos,
        rot,
        scale,
        gps: null,
        isWorldRot: false,
        refCoordSystemMatrix,
      },
      rootId: targetIElement.rootId,
      childrenIds: null,
      parentId: nodesGroup.id,
    },
    nodesGroupToCreate,
  );
}
