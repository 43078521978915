import { selectRevisionEntityWorldTransformCache } from "@/data-preparation-tool/store/revision-selectors";
import { PointCloudObject } from "@/object-cache";
import { useAppStore } from "@/store/store-hooks";
import { GUID } from "@faro-lotv/foundation";
import { Map2DControls as Map2DControlsImpl } from "@faro-lotv/lotv";
import { Camera } from "@react-three/fiber";
import { Ref, useCallback, useEffect, useRef } from "react";
import { Matrix4, Vector3 } from "three";
import { computeCombinedPointCloudCenter } from "../../utils/camera-views";
import {
  SinglePinControls,
  SinglePinControlsImperativeApi,
} from "./single-pin-controls";

type EntityPinControlsProps = {
  /**
   * The revision entity that can be manipulated by the user.
   * If `undefined`, the controls are disabled.
   */
  manipulatedEntityId?: GUID;

  /** The point clouds being manipulated by the entity */
  pointClouds: PointCloudObject[];

  /** The callback to execute when the object is manipulated by the user. */
  onTransform(worldTransform: Matrix4): void;

  /** The camera used for the controls */
  camera?: Camera;

  /** A ref for the map controls used with the pin controls */
  mapControlsRef: Ref<Map2DControlsImpl>;
};

/**
 * @returns A set of controls for the user to manipulate the transform of an entity.
 *
 * The user can left-click on any point cloud to place a pin.
 * - When dragging *on* the pin, the entity is translated
 * - When dragging *around* the pin, the entity is rotated.
 * - When right-clicking on the pin, the pin is removed
 */
export function EntityPinControls({
  manipulatedEntityId,
  pointClouds,
  mapControlsRef,
  camera,
  onTransform,
}: EntityPinControlsProps): JSX.Element {
  const { getState } = useAppStore();

  const isManipulationEnabled = !!manipulatedEntityId;

  // Accessor for the origin of the entity, to calculate the updated transform
  const getEntityTransform = useCallback(
    (): Matrix4 =>
      new Matrix4().fromArray(
        selectRevisionEntityWorldTransformCache(manipulatedEntityId)(getState())
          .worldMatrix,
      ),
    [getState, manipulatedEntityId],
  );

  // Accessor for the center of the entity, to improve pin positioning
  const getPointCloudsCenter = useCallback(() => {
    if (!pointClouds.length) {
      return new Vector3().fromArray(
        selectRevisionEntityWorldTransformCache(manipulatedEntityId)(getState())
          .position,
      );
    }

    return computeCombinedPointCloudCenter(pointClouds);
  }, [manipulatedEntityId, pointClouds, getState]);

  const pinInteractionRef = useRef<SinglePinControlsImperativeApi>(null);

  // Reset the pin when the entity changes
  useEffect(() => {
    if (manipulatedEntityId) {
      pinInteractionRef.current?.recenterPin();
    }
  }, [manipulatedEntityId]);

  return (
    <SinglePinControls
      ref={pinInteractionRef}
      isManipulationEnabled={isManipulationEnabled}
      getObjectTransform={getEntityTransform}
      getObjectCenter={getPointCloudsCenter}
      onTransform={onTransform}
      camera={camera}
      mapControlsRef={mapControlsRef}
    />
  );
}
