import { clearStore } from "@faro-lotv/app-component-toolbox";
import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";

/**
 * Shared state of the active elements of the project
 */
export type SelectionState = {
  /** The current active element */
  activeElement?: GUID;

  /** The current active sheet */
  activeSheet?: GUID;
};

const initialState: SelectionState = {
  activeElement: undefined,
  activeSheet: undefined,
};

/**
 * Slice to access global information about the current state of the active elements of the project
 */
const selectionsSlice = createSlice({
  name: "selection",
  initialState,
  reducers: {
    /**
     * Change the current active element of the app
     *
     * @param state Current state
     * @param action The id of the element
     */
    setActiveElement(state, action: PayloadAction<GUID | undefined>) {
      state.activeElement = action.payload;
    },

    /**
     * Change the current active sheet of the app
     *
     * @param state Current state
     * @param action The id of the sheet
     */
    setActiveSheet(state, action: PayloadAction<GUID | undefined>) {
      state.activeSheet = action.payload;
    },
  },
  extraReducers: (builder) => {
    builder.addCase(clearStore, () => initialState);
  },
});

export const selectionsReducer = selectionsSlice.reducer;

export const { setActiveElement, setActiveSheet } = selectionsSlice.actions;
