import { FaroText, neutral, NO_TRANSLATE_CLASS } from "@faro-lotv/flat-ui";
import { List, ListItem } from "@mui/material";

type ListSupportedCadFormatsProps = {
  /**
   * If true, the table will be displayed in dark mode.
   */
  dark: boolean;
};

/**
 * List of CAD formats supported by Sphere XG.
 * The list is basically the one provided by Autodesk APS (https://aps.autodesk.com/en/docs/model-derivative/v2/developers_guide/supported-translations/)
 * for formats supporting SVF output,
 * and when input file is a standalone monolithic file (as Sphere XG does not support yet multi-file CAD models)
 */
const SUPPORTED_CAD_FORMATS = [
  "3DM",
  "3DS",
  "A",
  "ASM, ASM0, ASM1...",
  "BRD",
  "CATPART",
  "CGR",
  "COLLABORATION",
  "DAE",
  "DGN",
  "DLV3",
  "DWF",
  "DWFX",
  "DWG",
  "DWT",
  "DXF",
  "EMODE",
  "EXP",
  "F3D",
  "FBX",
  "G",
  "GBXML",
  "GLB",
  "IAM",
  "IDW",
  "IFC",
  "IGE",
  "IGES",
  "IGS",
  "IPT",
  "IWM",
  "JT",
  "MAX",
  "MODEL",
  "MPF",
  "MSR",
  "NEU, NEU0, NEU1...",
  "NWC",
  "NWD",
  "OBJ",
  "PAR",
  "PDF",
  "PMLPRJ",
  "PMLPRJZ",
  "PRT (Creo)",
  "PRT (NX)",
  "PRT0, PRT1...",
  "PSM",
  "PSMODEL",
  "RVM",
  "RVT",
  "SAB",
  "SAT",
  "SCH",
  "SESSION",
  "SKP",
  "SLDPRT",
  "STE",
  "STEP",
  "STL",
  "STLA",
  "STLB",
  "STP",
  "STPZ",
  "VUE",
  "WIRE",
  "X_B",
  "X_T",
  "XAS",
  "XPR",
];

/**
 * Display the list of supported CAD formats as a plain text.
 *
 * @returns The list of supported CAD formats as a MUI's List
 */
export function ListSupportedCadFormats({
  dark,
}: ListSupportedCadFormatsProps): JSX.Element {
  const textColor = dark ? neutral[100] : neutral[800];

  return (
    <List>
      {SUPPORTED_CAD_FORMATS.map((format) => (
        <ListItem key={format}>
          <FaroText
            variant="bodyS"
            color={textColor}
            paddingTop={0}
            paddingBottom={0}
            className={NO_TRANSLATE_CLASS}
          >
            {format}
          </FaroText>
        </ListItem>
      ))}
    </List>
  );
}
