import { useAppStore } from "@/store/store-hooks";
import { TranslateVar } from "@faro-lotv/flat-ui";
import { convertToDateTimeString } from "@faro-lotv/foundation";
import {
  GUID,
  IElement,
  isIElementVideoRecording,
  pickClosestInTime,
} from "@faro-lotv/ielement-types";
import { selectAncestor } from "@faro-lotv/project-source";
import { useCallback, useMemo } from "react";
import { TimestampDropdownBase } from "./timestamp-dropdown/timestamp-dropdown-base";

type DataSessionsDropDownProps = {
  /** The list of datasets of interest for the current mode */
  datasets: IElement[];

  /** The element containing the time reference for the current active element  */
  referenceElement?: IElement;

  /** Called when the user selects a new data session from the drop down */
  onDataSessionChanged(id: GUID): void;
};

/**
 * @returns a dropdown to select the available odometric paths
 */
export function DataSessionsDropDown({
  datasets,
  referenceElement,
  onDataSessionChanged,
}: DataSessionsDropDownProps): JSX.Element | null {
  const store = useAppStore();

  const selectedElement = useMemo(
    () =>
      referenceElement
        ? pickClosestInTime(referenceElement, datasets)
        : undefined,
    [datasets, referenceElement],
  );

  const labelFormatter = useCallback(
    (iElement: IElement) => {
      const videoRecording = selectAncestor(
        iElement,
        isIElementVideoRecording,
      )(store.getState());

      const referenceElement = videoRecording ?? iElement;

      return (
        <TranslateVar name="elementLabel">
          {convertToDateTimeString(referenceElement.createdAt)} -{" "}
          {referenceElement.name}
        </TranslateVar>
      );
    },
    [store],
  );

  // Check if datasets contains referenceElement since it could be a room and we don't
  // want to show it
  if (datasets.length === 0 || !selectedElement) {
    return null;
  }

  return (
    <TimestampDropdownBase
      value={selectedElement.id}
      options={datasets}
      onChange={(e) => onDataSessionChanged(e.target.value)}
      labelFormatter={labelFormatter}
    />
  );
}
