import { PointCloudSubscene } from "@/components/r3f/effects/point-cloud-subscene";
import { useCenterCameraOnPointCloud } from "@/hooks/use-center-camera-on-pointcloud";
import { useCurrentScene } from "@/modes/mode-data-context";
import { PointCloudWithOpacity } from "@/modes/overview-mode/overview-point-cloud";
import { useCached3DObject } from "@/object-cache";
import { selectClippingBox } from "@/store/clipping-box-selectors";
import { setClippingBox } from "@/store/clipping-box-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import { ClipSceneTool } from "@/tools/clip-scene-tool";
import { isObjPointCloudPoint } from "@/types/threejs-type-guards";
import { useBoxControlsContext } from "@/utils/box-controls-context";
import {
  BoxControlsRef,
  CopyToScreenPass,
  EffectPipelineWithSubScenes,
  ExplorationControls,
  FilteredRenderPass,
  useNonExhaustiveEffect,
  useTypedEvent,
} from "@faro-lotv/app-component-toolbox";
import { assert } from "@faro-lotv/foundation";
import { isIElementPointCloudStream } from "@faro-lotv/ielement-types";
import { useThree } from "@react-three/fiber";
import { useCallback, useEffect, useMemo, useRef, useState } from "react";
import { Object3D, Plane, Vector3 } from "three";
import { OBB } from "three/examples/jsm/math/OBB";

const TEMP_OBB = new OBB();

/** @returns the scene for the clipping box mode */
export function ClippingBoxModeScene(): JSX.Element {
  const activeTool = useAppSelector(selectActiveTool);
  if (activeTool !== ToolName.clipping) {
    throw new Error("Clipping box tool: invalid active tool");
  }

  const { main } = useCurrentScene();
  assert(
    main && isIElementPointCloudStream(main),
    "Clipping Box Mode requires a PointCloud as the main element",
  );

  const camera = useThree((s) => s.camera);

  const pointCloudObject = useCached3DObject(main);

  const { hasUserInteracted, setHasUserInteracted } = useBoxControlsContext();

  const { box: modelBB, target } = useCenterCameraOnPointCloud(
    camera,
    pointCloudObject,
  );

  const [controlsEnabled, setControlsEnabled] = useState(true);

  // Reset the boolean to check if the user has interacted with the box controls on cleanup,
  // So that the help message can be shown on every entry to the clipping box mode
  useEffect(() => {
    return () => {
      setHasUserInteracted(false);
    };
  }, [setHasUserInteracted]);

  const onInteractionChanged = useCallback(
    (active: boolean) => {
      if (active) {
        if (!hasUserInteracted) {
          setHasUserInteracted(true);
        }
        setControlsEnabled(false);
      } else {
        setControlsEnabled(true);
      }
    },
    [hasUserInteracted, setHasUserInteracted],
  );

  const [clippingPlanesPreview, setClippingPlanesPreview] = useState<Plane[]>();
  const { resetBoxEvent, setClippingPlanes } = useBoxControlsContext();

  const boxRef = useRef<BoxControlsRef>();

  // Updates the preview clipping box and calculates the local clipping planes for the clipping box state
  const onClippingPlanesChanged = useCallback(
    (clippingPlanes: Plane[]) => {
      // The clipping planes for the preview should always be defined and in world space
      setClippingPlanesPreview(clippingPlanes);

      if (!boxRef.current) return;
      // Compute an OBB (Oriented Bounding Box) to check if the user defined box is valid and intersect the PC box
      const trx = boxRef.current.boxMatrixWorld;
      TEMP_OBB.center.set(0, 0, 0);
      TEMP_OBB.halfSize.set(0.5, 0.5, 0.5);
      TEMP_OBB.rotation.identity();
      TEMP_OBB.applyMatrix4(trx);
      // If any of the obb halfSize is 0.01 then the box is too small
      // 0.01 is the distance at which two box handle will overlap
      const MIN_SIZE = 0.01;
      if (
        TEMP_OBB.halfSize.x < MIN_SIZE ||
        TEMP_OBB.halfSize.y < MIN_SIZE ||
        TEMP_OBB.halfSize.z < MIN_SIZE
      ) {
        setClippingPlanes(undefined);
        return;
      }
      const hit = TEMP_OBB.intersectsBox3(modelBB);
      // If there's no intersection we don't have a valid set of planes to extract a volume
      if (!hit) {
        setClippingPlanes(undefined);
        return;
      }
      // Copy the array, otherwise the hooks that depend on clippingPlanes will not get called
      setClippingPlanes(clippingPlanes.slice());
    },
    [modelBB, setClippingPlanes],
  );

  const dispatch = useAppDispatch();
  useTypedEvent(
    resetBoxEvent,
    useCallback(() => dispatch(setClippingBox(undefined)), [dispatch]),
  );

  // If the app clipping box is not defined, initialize it with the model box
  // TODO: review this hook logic if we will ever implement the persistence of the clipping box
  const clippingBox = useAppSelector(selectClippingBox);
  useNonExhaustiveEffect(() => {
    if (!clippingBox) {
      dispatch(
        setClippingBox({
          position: modelBB.getCenter(new Vector3()).toArray(),
          rotation: [0, 0, 0, 1],
          size: modelBB.getSize(new Vector3()).toArray(),
        }),
      );
      return () => {
        dispatch(setClippingBox(undefined));
      };
    }
  }, [dispatch, modelBB]);

  const interactiveObjects = useMemo(
    () => [pointCloudObject],
    [pointCloudObject],
  );

  // TODO: uncomment the different parts when we have controls for them.
  // I left them in to make it easier for the next dev to  know what they are.
  return (
    <>
      <PointCloudWithOpacity
        pointCloud={pointCloudObject}
        visible
        clippingPlanes={clippingPlanesPreview}
      />
      <ClipSceneTool
        ref={boxRef}
        modelBox={modelBB}
        clippingPlanesChanged={onClippingPlanesChanged}
        clippingBoxChanging={onInteractionChanged}
      />
      <ExplorationControls
        target={target}
        enabled={controlsEnabled}
        obstacles={interactiveObjects}
      />
      <EffectPipelineWithSubScenes>
        <PointCloudSubscene pointCloud={pointCloudObject} />
        <FilteredRenderPass
          filter={(obj: Object3D) => !isObjPointCloudPoint(obj)}
          clear={false}
          clearDepth={false}
        />
        <CopyToScreenPass />
      </EffectPipelineWithSubScenes>
    </>
  );
}
