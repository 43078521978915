import { selectModelClippingBoxEnabled } from "@/store/modes/cloud-to-cad-alignment-mode-selectors";
import { setModelClippingBoxEnabled } from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  ClippingBoxIcon,
  ToolButton,
  ToolGroup,
  neutral,
} from "@faro-lotv/flat-ui";
import { Toolbar, Tooltip } from "@mui/material";
import { ModelElevationTool } from "./model-elevation-tool";

/** @returns The set-elevation step overlay for 3D model part */
export function ModelElevationOverlay(): JSX.Element {
  const dispatch = useAppDispatch();

  const isClippingBoxEnabled = useAppSelector(selectModelClippingBoxEnabled);

  return (
    <>
      <Toolbar
        sx={{
          position: "absolute",
          right: 1,
          top: "50%",
        }}
      >
        <ToolGroup>
          <Tooltip title="Clip 3D model" placement="left">
            <ToolButton
              sx={{ backgroundColor: neutral[999] }}
              selected={isClippingBoxEnabled}
              onClick={() => {
                dispatch(setModelClippingBoxEnabled(!isClippingBoxEnabled));
              }}
            >
              <ClippingBoxIcon sx={{ width: "24px", height: "24px" }} />
            </ToolButton>
          </Tooltip>
        </ToolGroup>
      </Toolbar>
      <ModelElevationTool />
    </>
  );
}
