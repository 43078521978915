import { EventType } from "@/analytics/analytics-events";
import { changeMode } from "@/store/mode-slice";
import {
  selectWizardElementToAlignId,
  selectWizardReferenceElementId,
} from "@/store/modes/alignment-wizard-mode-selectors";
import { resetAlignmentWizard } from "@/store/modes/alignment-wizard-mode-slice";
import { setCloudForCloudAlignment } from "@/store/modes/cloud-to-cad-alignment-mode-slice";
import {
  setSheetForCadAlignment,
  setSheetToCadAlignmentStep,
  SheetToCadAlignmentStep,
} from "@/store/modes/sheet-to-cad-alignment-mode-slice";
import {
  setReferenceCloudForAlignment,
  setSheetIdForAlignment,
  setStepForSheetToCloudAlignment,
  SheetToCloudAlignmentStep,
} from "@/store/modes/sheet-to-cloud-alignment-mode-slice";
import { store } from "@/store/store";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  redirectToAlignmentTool,
  redirectToRegistrationTool,
} from "@/utils/redirects";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  isIElementAreaSection,
  isIElementGenericImgSheet,
  isIElementGenericModel3d,
  isIElementGenericPointCloudStream,
  isIElementModel3dStream,
  isIElementSectionDataSession,
} from "@faro-lotv/ielement-types";
import {
  selectChildDepthFirst,
  selectIElement,
  selectProjectId,
} from "@faro-lotv/project-source";
import { useCallback } from "react";
import { AlignWizardProgressBar } from "./align-wizard-progress-bar";
import { AlignWizardSplitScreen } from "./align-wizard-split-screen";

/** @returns The overlay for the cloud to CAD alignment mode */
export function AlignWizardModeOverlay(): JSX.Element {
  const dispatch = useAppDispatch();

  const projectId = useAppSelector(selectProjectId);
  assert(projectId, "invalid number project id");

  const elementToAlignId = useAppSelector(selectWizardElementToAlignId);
  const referenceElementId = useAppSelector(selectWizardReferenceElementId);

  const selectAndRunAlignment = useCallback(() => {
    const alignedElement = selectIElement(elementToAlignId)(store.getState());
    assert(
      alignedElement,
      "It should be not possible to open Alignment Wizard without aligned element being selected",
    );

    const referenceElement = selectIElement(referenceElementId)(
      store.getState(),
    );
    if (referenceElement === undefined) return;

    if (isIElementAreaSection(alignedElement)) {
      assert(
        isIElementGenericModel3d(referenceElement) ||
          isIElementSectionDataSession(referenceElement),
        "invalid element type",
      );

      const sheet = selectChildDepthFirst(
        alignedElement,
        isIElementGenericImgSheet,
      )(store.getState());
      assert(sheet, "Sheet not found for selected floor.");

      if (isIElementGenericModel3d(referenceElement)) {
        // sheet to cad alignment
        dispatch(
          setSheetToCadAlignmentStep(SheetToCadAlignmentStep.setElevation),
        );
        dispatch(setSheetForCadAlignment(sheet.id));
        dispatch(changeMode("sheetToCadAlignment"));
      } else {
        // sheet to cloud alignment
        const cloud = selectChildDepthFirst(
          referenceElement,
          isIElementGenericPointCloudStream,
        )(store.getState());
        assert(
          cloud,
          "Sheet-to-cloud alignment should not be called when there is no point cloud selected",
        );

        dispatch(
          setStepForSheetToCloudAlignment(
            SheetToCloudAlignmentStep.setElevation,
          ),
        );
        dispatch(setSheetIdForAlignment(sheet.id));
        dispatch(setReferenceCloudForAlignment(cloud.id));
        dispatch(changeMode("sheetToCloudAlignment"));
      }
    } else {
      assert(
        isIElementSectionDataSession(alignedElement),
        "point cloud for alignment not defined",
      );

      if (isIElementAreaSection(referenceElement)) {
        redirectToAlignmentTool({
          projectId,
          elementId: alignedElement.id,
          floorId: referenceElement.id,
          state: store.getState(),
          dispatch,
        });
      } else if (isIElementSectionDataSession(referenceElement)) {
        Analytics.track(EventType.startPairwiseRegistrationWorkflow);

        redirectToRegistrationTool({
          projectId,
          pointCloudId1: referenceElement.id,
          pointCloudId2: alignedElement.id,
        });
      } else if (isIElementModel3dStream(referenceElement)) {
        const cloud = selectChildDepthFirst(
          alignedElement,
          isIElementGenericPointCloudStream,
        )(store.getState());

        assert(
          cloud,
          "Cloud-To-Cad alignment should not be called when there is no point cloud selected",
        );

        dispatch(setCloudForCloudAlignment(cloud.id));
        dispatch(changeMode("cloudToCadAlignment"));
      } else {
        throw new Error(
          "Invalid combination of reference and aligned elements in Alignment Wizard",
        );
      }
    }

    dispatch(resetAlignmentWizard());
  }, [dispatch, elementToAlignId, projectId, referenceElementId]);

  return (
    <>
      <AlignWizardProgressBar goToNextStep={selectAndRunAlignment} />
      <AlignWizardSplitScreen />
    </>
  );
}
