import { Features, selectHasFeature } from "@/store/features/features-slice";
import { useAppSelector } from "@/store/store-hooks";
import { selectCanUseSendToTool } from "@/store/subscriptions/subscriptions-selectors";

/** @returns true if the current user can use the send to tool */
export function useCanUseSendToTool(): boolean {
  const isSendToToolEnabled = useAppSelector(
    selectHasFeature(Features.SendToTool),
  );
  const hasSendToSubscription = useAppSelector(selectCanUseSendToTool);

  return isSendToToolEnabled && hasSendToSubscription;
}
