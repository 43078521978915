import {
  ComputeHiddenPlaceholdersState,
  useViewportRef,
} from "@faro-lotv/app-component-toolbox";
import { pixels2m } from "@faro-lotv/lotv";
import { useThree } from "@react-three/fiber";
import { RefObject, useCallback, useRef } from "react";
import { Group, Vector3 } from "three";

/** Temporary vector for math computations */
const WORLD_SCALE = new Vector3();

type SubSamplePlaceholdersReturn = {
  /** The function used to filer out a set of points */
  computeHiddenPlaceholders(state: ComputeHiddenPlaceholdersState): number[];
  /** The group of which the placeholders renderer is child */
  groupRef: RefObject<Group>;
};

/**
 * Return a callback to filter a set of placeholders rendered as points
 *
 * @param references The list of extra positions the placeholders should not overlap with
 * @param pixelSize The size in pixels of the points
 * @param locked The list of locked positions
 * @returns The group reference and the filtering function
 */
export function useSubSamplePlaceholders(
  references: Vector3[],
  pixelSize: number,
  locked: boolean[] = [],
): SubSamplePlaceholdersReturn {
  const camera = useThree((state) => state.camera);
  const viewport = useViewportRef();

  const groupRef = useRef<Group>(null);
  const visiblePoints = useRef<Vector3[]>([]);
  const lastThreshold = useRef<number>();

  // Computes the hidden placeholders based on their screen-space distance
  // Prioritizes showing the reference positions (start and end placeholders)
  const computeHiddenPlaceholders = useCallback(
    ({
      positions,
      previousHidden,
      havePointsChanged,
    }: ComputeHiddenPlaceholdersState) => {
      if (!viewport.current) return [];

      const scale = groupRef.current?.getWorldScale(WORLD_SCALE).x ?? 1;
      // Min distance between two points, taking into account the world scale applied to the
      // entire point group (assuming it's uniform)
      let threshold = pixels2m(
        (pixelSize * 2) / scale,
        camera,
        viewport.current.height,
        camera.position.y,
      );

      // Quantize in exponential space
      threshold = Math.log2(threshold);
      threshold = Math.round(threshold);
      threshold = Math.pow(2, threshold);

      // Calculate square to optimize distance query
      const thresholdSq = threshold * threshold;

      if (lastThreshold.current === thresholdSq && !havePointsChanged) {
        return previousHidden;
      }
      lastThreshold.current = thresholdSq;

      const hidden = [];
      visiblePoints.current.length = 0;
      for (let idx = 0; idx < positions.length; ++idx) {
        const toHide =
          references.some(
            (p) => positions[idx].distanceToSquared(p) < thresholdSq,
          ) ||
          visiblePoints.current.some(
            (point) => point.distanceToSquared(positions[idx]) < thresholdSq,
          );
        if (toHide && !locked[idx]) {
          hidden.push(idx);
        } else {
          visiblePoints.current.push(positions[idx]);
        }
      }
      return hidden;
    },
    [camera, locked, pixelSize, references, viewport],
  );

  return { groupRef, computeHiddenPlaceholders };
}
