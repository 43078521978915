import { TypedEvent } from "@faro-lotv/foundation";
import { useMemo } from "react";
import { Plane } from "three";
import { create } from "zustand";
import { ClippingBoxPlanes } from "./planes-to-array";

/** A context to manage the interaction with a BoxControl */
export type BoxControlsContext = {
  /** Event to trigger to reset the box of the box controls*/
  resetBoxEvent: TypedEvent<void>;

  /** Event to trigger to auto compute a box based on the current depth buffer */
  autoBoxEvent: TypedEvent<void>;

  /** The six planes defining the clipping box if a valid set is available */
  clippingPlanes?: Plane[];

  /**
   * Set the six planes defining the clipping box or undefined if there are no valid planes available
   *
   * The planes are valid if all the sizes of the selection box are > 0.01 and
   * the user defined box intersects the point cloud bounding box
   *
   * They selection box may still contain no points
   */
  setClippingPlanes(planes: Plane[] | undefined): void;

  /** A flag to know if the user interacted with the box controls in this editing session */
  hasUserInteracted: boolean;

  /** Change the flag to know if the user interacted with the box controls in this session */
  setHasUserInteracted(hasUserInteracted: boolean): void;
};

/** Context containing useful data for the Clipping Box mode */
export const useBoxControlsContext = create<BoxControlsContext>((set) => ({
  resetBoxEvent: new TypedEvent<void>(),
  autoBoxEvent: new TypedEvent<void>(),
  clippingPlanes: undefined,
  setClippingPlanes: (clippingPlanes: Plane[] | undefined) =>
    set({ clippingPlanes }),
  hasUserInteracted: false,
  setHasUserInteracted: (hasUserInteracted) => set({ hasUserInteracted }),
}));

/**
 * @returns The two triplets of clipping planes that describe the minimum and the maximum of the bounding box
 */
export function useBoxControlsClippingPlanes(): ClippingBoxPlanes | undefined {
  const { clippingPlanes } = useBoxControlsContext();

  return useMemo<ClippingBoxPlanes | undefined>(
    () =>
      clippingPlanes
        ? {
            // The BoxControls defines the clipping planes with normals [-X, +X, -Y, +Y, -Z, +Z], in this order.
            // Since we are using the gizmo z-up, we assume that the minimum is mapped to
            // the intersection of the planes corresponding to +X, +Z, -Y.
            // This way, the matrix will not contain any 90° rotation around X, but just the
            // rotation around Y.
            // We will need to change this if we will ever move to a Z-up viewer.
            min: [clippingPlanes[1], clippingPlanes[5], clippingPlanes[2]],
            max: [clippingPlanes[0], clippingPlanes[4], clippingPlanes[3]],
          }
        : undefined,
    [clippingPlanes],
  );
}
