import { CameraParameters } from "@/components/r3f/utils/camera-parameters";
import { OrientedBoundingBox } from "@/components/r3f/utils/oriented-bounding-box";
import { ModeNames } from "@/modes/mode";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

/** State specific for the clipping box mode */
type ExportModeState = {
  /** The mode to return to after the export */
  returnMode?: ModeNames;

  /** The camera to restore after the export */
  returnCamera?: CameraParameters;

  /** The initial camera target to use for the export workflow */
  initialTarget?: Vector3Tuple;

  /** The initial clipping box */
  clippingBox?: OrientedBoundingBox;
};

const EXPORT_MODE_INITIAL_STATE: ExportModeState = {};

export const exportModeSlice = createSlice({
  initialState: EXPORT_MODE_INITIAL_STATE,
  name: "exportModeSlice",
  reducers: {
    prepareExport(
      state,
      action: PayloadAction<{
        returnMode: ModeNames;
        returnCamera: CameraParameters;
        initialTarget?: Vector3Tuple;
        clippingBox?: OrientedBoundingBox;
      }>,
    ) {
      state.returnMode = action.payload.returnMode;
      state.returnCamera = action.payload.returnCamera;
      state.initialTarget = action.payload.initialTarget;
      state.clippingBox = action.payload.clippingBox;
    },
  },
});

export const { prepareExport } = exportModeSlice.actions;

export const exportModeReducer = exportModeSlice.reducer;
