import { useAppSelector } from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { ToolName } from "@/store/ui/ui-slice";
import {
  LENS_CENTER,
  LensActive,
  LensDefault,
  useThreeEventTarget,
} from "@faro-lotv/app-component-toolbox";
import { TypedEvent } from "@faro-lotv/foundation";
import { ThreeEvent } from "@react-three/fiber";
import { DomEvent } from "@react-three/fiber/dist/declarations/src/core/events";
import { forwardRef, useEffect, useMemo, useState } from "react";
import { EventDispatcher, MOUSE, Vector3Tuple } from "three";
import { ToolControlsLogic, ToolControlsRef } from "../tool-controls-interface";
import { useSendToRevitContext } from "./send-to-revit-context";

class SendToControlsLogic extends EventDispatcher implements ToolControlsLogic {
  onPointPick = new TypedEvent<Vector3Tuple>();

  constructor() {
    super();
  }

  pointHovered(ev: ThreeEvent<DomEvent>): void {
    ev.stopPropagation();
  }

  pointClicked(ev: ThreeEvent<DomEvent>): void {
    if (ev.button === MOUSE.LEFT) {
      const pointClicked = ev.point;
      ev.stopPropagation();
      this.onPointPick.emit(pointClicked.toArray());
    }
  }
}

export type SendToControlsProps = {
  /** Callback issued when a point has been picked */
  onPointPick(point: Vector3Tuple): void;
  /** Callback issued when the user presses ESC key */
  onEscPressed?(): void;
};

/** @returns A component that provides mouse/touch controls logic for the SendTo tool. */
export const SendToControls = forwardRef<ToolControlsRef, SendToControlsProps>(
  function SendToControls(
    { onPointPick, onEscPressed }: SendToControlsProps,
    ref,
  ): JSX.Element {
    const { isConnected } = useSendToRevitContext();

    const isToolActive = useAppSelector(selectActiveTool) === ToolName.sendTo;

    const domElement = useThreeEventTarget();
    const [initialCursor] = useState(domElement.style.cursor);

    // Creating the controls logic
    const controls = useMemo(() => {
      const controls = new SendToControlsLogic();
      controls.onPointPick.on((p) => onPointPick(p));
      return controls;
    }, [onPointPick]);

    // Bind the escape key to cancel the current SendTo
    // Making sure to unbind on close or when controls change before binding to the new controls.
    useEffect(() => {
      // If the tool is not active, don't add the event listeners
      if (!isToolActive) return;

      const handleKeyDown = (event: KeyboardEvent): void => {
        if (event.key === "Escape") {
          onEscPressed?.();
        }
      };
      window.addEventListener("keydown", handleKeyDown);

      return () => {
        window.removeEventListener("keydown", handleKeyDown);
      };
    }, [controls, isToolActive, onEscPressed]);

    /**
     * Effect to handle the changes to cursor style when the SendTo tool is active and on point clicked
     */
    useEffect(() => {
      function changeToSendToCursor(isSendToStarted?: boolean): void {
        domElement.style.cursor = `url("${
          isSendToStarted ? LensActive : LensDefault
        }") ${LENS_CENTER} ${LENS_CENTER},auto`;
      }

      function onPointerDown(ev: PointerEvent): void {
        if (ev.button === MOUSE.LEFT) changeToSendToCursor(true);
      }
      function onPointerUp(): void {
        changeToSendToCursor();
      }

      if (isConnected) {
        changeToSendToCursor();
        domElement.addEventListener("pointerdown", onPointerDown);
        domElement.addEventListener("pointerup", onPointerUp);
      } else {
        domElement.style.cursor = initialCursor;
      }

      return () => {
        domElement.removeEventListener("pointerdown", onPointerDown);
        domElement.removeEventListener("pointerup", onPointerUp);
        domElement.style.cursor = initialCursor;
      };
    }, [domElement, initialCursor, isConnected]);

    return <primitive ref={ref} object={controls} />;
  },
);
