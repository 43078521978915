import { HelpPopover } from "@/components/ui/help-popover";
import { canExport, computeExportLimit } from "@/utils/pc-export-utils";
import {
  FaroChipToggle,
  FaroDialog,
  FaroRadio,
  FaroRadioGroup,
  FaroSlider,
  FaroSliderProps,
  FaroText,
  neutral,
  TranslateVar,
} from "@faro-lotv/flat-ui";
import { PointCloudFormat } from "@faro-lotv/service-wires";
import {
  ButtonBaseActions,
  FormControlLabel,
  Stack,
  Typography,
} from "@mui/material";
import { useCallback, useRef, useState } from "react";

/** The expected original density of a point cloud */
export const ORIGINAL_EXPECTED_DENSITY = 1;

/**
 * List of possible point cloud densities for the export.
 * The value is the distance between points in mm.
 * The values are ordered from the lowest to the highest density.
 */
export const CUSTOM_DENSITY_VALUES = [
  {
    value: 2,
  },
  {
    value: 4,
  },
  {
    value: 8,
  },
  {
    value: 16,
  },
  {
    value: 32,
  },
  {
    value: 64,
  },
] satisfies FaroSliderProps["marks"];

export type ClippingBoxExportDialogProps = {
  /** True to open the dialog */
  open: boolean;

  /** True to disable interaction on the dialog elements */
  disabled: boolean;

  /** True to show a spinner inside the accept button */
  showSpinner: boolean;

  /** List of PointCloudFormat the user can choose from */
  formats: PointCloudFormat[];

  /** Current volume to export in cubic meters */
  exportVolume: number;

  /** True to use a custom export density */
  useCustomDensity: boolean;

  /** A custom density defined in millimeters */
  customDensity: number;

  /** Notify that the user changed the custom density selection */
  onUseCustomDensityChanged(useCustomDensity: boolean): void;

  /** Notify the user changed the custom density value to */
  onDensityChanged(density: number): void;

  /** Callback called when the user cancel the dialog */
  onClose(): void;

  /**
   * Callback called when the user confirm the export selection
   *
   * @param format the format to use for the export
   */
  onConfirm(format: string): void;
};

/** @returns the dialog to select what format to use to export a PointCloud SubVolume */
export function ClippingBoxExportDialog({
  open,
  disabled,
  showSpinner,
  formats,
  exportVolume,
  useCustomDensity,
  customDensity,
  onUseCustomDensityChanged,
  onDensityChanged,
  onClose,
  onConfirm,
}: ClippingBoxExportDialogProps): JSX.Element | null {
  const [chosenFormat, setChosenFormat] = useState<PointCloudFormat>();
  const cancelButtonActions = useRef<ButtonBaseActions>(null);
  const handleClose = useCallback(
    (_: unknown, reason: string) => {
      if (cancelButtonActions.current && reason === "backdropClick") {
        cancelButtonActions.current.focusVisible();
      } else {
        onClose();
      }
    },
    [onClose],
  );

  const currentDensity = useCustomDensity
    ? customDensity
    : ORIGINAL_EXPECTED_DENSITY;

  const areOptionsValid =
    chosenFormat && canExport(chosenFormat, currentDensity, exportVolume);

  return (
    <FaroDialog
      title="Export Volume Format"
      open={open}
      onConfirm={() => {
        if (chosenFormat) {
          onConfirm(chosenFormat.name);
        }
      }}
      onCancel={onClose}
      onClose={handleClose}
      showSpinner={showSpinner}
      disabled={disabled}
      cancelButtonActions={cancelButtonActions}
      isConfirmDisabled={!areOptionsValid}
      confirmText="Export"
      dark
      sx={{
        // Override the overflow of the dialog to allow the slider to be fully visible without a scrollbar
        // and not be cropped by the dialog.
        "> .MuiDialog-container > .MuiPaper-root > *": {
          overflow: "visible",
          "> .MuiDialogContent-root": {
            overflow: "visible",
          },
        },
      }}
    >
      <Stack gap={3}>
        <Typography>Select the file format to export your volume</Typography>
        <FaroRadioGroup
          value={chosenFormat?.name ?? "undefined"}
          onChange={(v) =>
            setChosenFormat(
              formats.find((format) => format.name === v.target.value),
            )
          }
        >
          {formats.map((format) => {
            const disabled = !canExport(format, currentDensity, exportVolume);
            return (
              <FormControlLabel
                key={format.name}
                value={format.name}
                disabled={disabled}
                control={<FaroRadio dark />}
                labelPlacement="end"
                label={
                  <ExportLabel
                    format={format}
                    density={currentDensity}
                    exportVolume={exportVolume}
                  />
                }
                aria-label={format.displayName}
                sx={{
                  m: 0,
                  ".MuiFormControlLabel-label": {
                    width: "100%",
                  },
                }}
              />
            );
          })}
        </FaroRadioGroup>
      </Stack>
      <ResolutionSelector
        shouldUseCustomDensity={useCustomDensity}
        onToggleCustomDensity={onUseCustomDensityChanged}
        density={customDensity}
        onDensityChanged={onDensityChanged}
      />
    </FaroDialog>
  );
}

type ResolutionSelectorProps = {
  /** True if the user want to export using a custom density */
  shouldUseCustomDensity: boolean;

  /** Called when the user opt-in or opt-out to use a custom density export value */
  onToggleCustomDensity(shouldUseCustom: boolean): void;

  /** The custom density value to use */
  density: number;

  /** Called when the user changes the custom density value */
  onDensityChanged(density: number): void;
};

function ResolutionSelector({
  shouldUseCustomDensity,
  onToggleCustomDensity,
  density,
  onDensityChanged,
}: ResolutionSelectorProps): JSX.Element {
  return (
    <Stack marginTop={3}>
      <FaroText dark variant="heading14">
        Minimum distance between points
      </FaroText>
      <Stack direction="row" sx={{ my: 1, mx: 0, gap: 1 }}>
        <FaroChipToggle
          dark
          label="Original"
          selected={!shouldUseCustomDensity}
          onClick={() => onToggleCustomDensity(false)}
        />
        <FaroChipToggle
          dark
          label="Custom"
          selected={shouldUseCustomDensity}
          onClick={() => onToggleCustomDensity(true)}
        />
      </Stack>
      <Stack sx={{ visibility: shouldUseCustomDensity ? "visible" : "hidden" }}>
        <FaroSlider
          dark
          marks={CUSTOM_DENSITY_VALUES}
          step={null}
          min={CUSTOM_DENSITY_VALUES[0].value}
          max={CUSTOM_DENSITY_VALUES[CUSTOM_DENSITY_VALUES.length - 1].value}
          value={density}
          onChange={(_, value) => {
            if (typeof value === "number") {
              onDensityChanged(value);
            }
          }}
          valueLabelDisplay="auto"
        />
        <Stack direction="row" justifyContent="space-between">
          <FaroText dark variant="placeholder">
            2 mm
          </FaroText>
          <FaroText dark variant="placeholder">
            64 mm
          </FaroText>
        </Stack>
      </Stack>
    </Stack>
  );
}

type ExportLabelProps = {
  /** Format for the export */
  format: PointCloudFormat;

  /** Volume to export */
  exportVolume: number;

  /** Current selected export density in millimeters between points */
  density: number;
};

/**
 * @returns the label for the radio selection, with an additional warning message if needed
 */
function ExportLabel({
  format,
  exportVolume,
  density,
}: ExportLabelProps): JSX.Element {
  if (canExport(format, density, exportVolume)) {
    return (
      <FaroText variant="bodyL" dark>
        {format.displayName}
      </FaroText>
    );
  }

  const limit = computeExportLimit(format, density);
  const current = Math.ceil(exportVolume);
  return (
    <Stack
      direction="row"
      justifyContent="space-between"
      sx={{ width: "100%" }}
    >
      <FaroText variant="bodyL" dark color={neutral[100]} sx={{ opacity: 0.5 }}>
        <TranslateVar name="exportFormat">{format.displayName}</TranslateVar>
      </FaroText>
      <HelpPopover
        title={`${format.displayName} unavailable`}
        variant="warning"
        description={
          <Stack direction="column" gap={1}>
            <FaroText variant="bodyL" dark color={neutral[300]}>
              The current selected volume of{" "}
              <TranslateVar name="currentVolume">{current}</TranslateVar> m
              {"\u00B3"} is higher than the exportable volume of{" "}
              <TranslateVar name="limit">{limit}</TranslateVar> {"m\u00B3"}{" "}
              <br />
            </FaroText>
            <FaroText variant="bodyL" dark color={neutral[300]}>
              To resolve the issue, increase the minimum distance between points
              or reduce the volume to export
            </FaroText>
          </Stack>
        }
      />
    </Stack>
  );
}
