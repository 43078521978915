import {
  AlignPointCloudEventProperties,
  EventType,
} from "@/analytics/analytics-events";
import { useCancelUpload } from "@/components/common/file-upload-context/use-cancel-upload";
import { isSupportedCADFileExtension } from "@/components/common/point-cloud-file-upload-context/cad-upload-utils";
import {
  ElementIcon,
  ElementIconType,
  iconForElement,
} from "@/components/ui/icons";
import { openAlignmentWizard } from "@/modes/alignment-wizard/open-alignment-wizard";
import { selectReportTimeseriesDataSession } from "@/registration-tools/utils/multi-registration-report";
import { selectBackgroundTask } from "@/store/background-tasks/background-tasks-selector";
import { removeBackgroundTask } from "@/store/background-tasks/background-tasks-slice";
import {
  selectActiveArea,
  selectActiveAreaOrThrow,
} from "@/store/selections-selectors";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import {
  BackgroundTask,
  FileUploadTask,
  GenericRegistrationTask,
  ProcessingTask,
  getErrorCodeAndTaskName,
  isC2CRegistrationTask,
  isFileUploadTask,
  isGenericRegistrationTask,
  isProcessingTask,
  isRegisterMultiCloudDataSetTask,
} from "@/utils/background-tasks";
import { downloadFile } from "@/utils/download";
import { redirectToMultiRegistrationTool } from "@/utils/redirects";
import {
  selectAncestor,
  selectChildDepthFirst,
  selectIElement,
  selectIsPointCloudAligned,
  selectNumberOfPointCloudsOnFloor,
} from "@faro-lotv/app-component-toolbox";
import {
  FaroButton,
  FaroIconButton,
  FaroTooltip,
  FontWeights,
  NoTranslate,
  RemainingTimeLabel,
  ThreeDotsIcon,
  TruncatedText,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import { assert } from "@faro-lotv/foundation";
import {
  GUID,
  isIElementBimModelSection,
  isIElementGenericPointCloudStream,
  isIElementGenericStream,
  isIElementSectionDataSession,
  isIElementTimeseriesDataSession,
} from "@faro-lotv/ielement-types";
import {
  BackgroundTaskState,
  ProgressApiSupportedTaskTypes,
  isBackgroundTaskActive,
  taskErrorToUserMessage,
  useApiClientContext,
} from "@faro-lotv/service-wires";
import {
  Box,
  ButtonProps,
  LinearProgress,
  Menu,
  MenuItem,
  Stack,
  SxProps,
  Theme,
  Typography,
} from "@mui/material";
import {
  PropsWithChildren,
  ReactNode,
  useCallback,
  useEffect,
  useMemo,
  useRef,
  useState,
} from "react";
import {
  compatibilityMessage,
  useDisableCaptureTreeAlignment,
} from "../tree/cad-model-tree/use-disable-capture-tree-alignment";
import { ProcessInfoSource } from "./progress-overview-selectors";

// Padding used in the Progress card. This is needed because no magic numbers are allowed.
const TITLE_TO_PROGRESS_PADDING = 1.25;

type ProgressOverviewCardProps = {
  /** Source of information about a specific upload */
  source: ProcessInfoSource;
};

/** @returns the card to show the user the state of a PointCloud or CAD upload */
export function ProgressOverviewCard({
  source,
}: ProgressOverviewCardProps): JSX.Element {
  switch (source.type) {
    case "ElementToAlignSection":
      return <ToAlignCard iElementID={source.id} />;
    case "Task":
      return <TaskCard taskID={source.id} />;
  }
}

type RegistrationTaskCardProps = {
  /** The task to show a registration card for */
  task: GenericRegistrationTask;
};

/**
 * @returns A card to track the progress of a registration task.
 */
export function RegistrationTaskCard({
  task,
}: RegistrationTaskCardProps): JSX.Element | null {
  const { projectApiClient } = useApiClientContext();

  const taskElement = useAppSelector(selectIElement(task.iElementId ?? ""));
  const dataSessionSection = useAppSelector((state) =>
    selectAncestor(taskElement, isIElementSectionDataSession)(state),
  );
  const dataSessionTimeSeries = useAppSelector((state) =>
    selectAncestor(taskElement, isIElementTimeseriesDataSession)(state),
  );

  const displayElement = isC2CRegistrationTask(task)
    ? dataSessionSection
    : dataSessionTimeSeries;
  const activeArea = useAppSelector(selectActiveAreaOrThrow(displayElement));

  const icon = displayElement
    ? iconForElement(displayElement.type, displayElement.typeHint)
    : undefined;

  const store = useAppStore();

  // Redirect to the multi cloud view for the registered dataset
  const action: CardAction | undefined =
    isRegisterMultiCloudDataSetTask(task) &&
    task.state === BackgroundTaskState.succeeded
      ? {
          name: "View Results",
          callback: () => {
            const registrationReport = task.result;
            assert(
              registrationReport,
              "Registration report missing or with invalid format",
            );

            const reportDatasession = selectReportTimeseriesDataSession(
              registrationReport,
            )(store.getState());
            assert(
              reportDatasession,
              "No datasession detected for registration report",
            );

            redirectToMultiRegistrationTool({
              projectId: projectApiClient.projectId,
              datasetId: reportDatasession.id,
              registrationTaskId: task.id,
            });
          },
        }
      : undefined;

  // The "4D Sessions" name is hard-coded for these elements in the project tree
  // We follow the same format for consistency
  let cardName: string | JSX.Element = "Registration";
  if (displayElement && isIElementTimeseriesDataSession(displayElement)) {
    cardName = "4D Sessions";
  } else if (displayElement?.name) {
    cardName = <NoTranslate>{displayElement.name}</NoTranslate>;
  }

  return (
    <GenericCardLayout
      name={cardName}
      icon={icon}
      floor={<NoTranslate>{activeArea.name}</NoTranslate>}
      action={action}
    >
      {isBackgroundTaskActive(task.state) && (
        <ProgressCardProgress label="Registering Point Clouds" task={task} />
      )}
    </GenericCardLayout>
  );
}

type ToExportCardProps = {
  /** Id of the task to show an export card for */
  taskID: GUID;
};

/** @returns the export volume card with a button to download a pointcloud volume */
export function ToExportCard({
  taskID,
}: ToExportCardProps): JSX.Element | null {
  const task = useAppSelector(selectBackgroundTask(taskID));

  assert(task, "a TaskCard need to be created on an existing BackgroundTask");

  const refElement = useAppSelector((state) =>
    selectAncestor(
      selectIElement(task.iElementId ?? "")(state),
      isIElementSectionDataSession,
    )(state),
  );
  const activeArea = useAppSelector(selectActiveAreaOrThrow(refElement));

  const downloadExportedVolume = useCallback(() => {
    if (task.metadata?.downloadUrl !== undefined) {
      downloadFile(task.metadata.downloadUrl);
    }
  }, [task.metadata?.downloadUrl]);

  let cardName: string | JSX.Element = "Download Volume";
  if (refElement?.name) {
    cardName = <NoTranslate>{refElement.name}</NoTranslate>;
  }

  return (
    <GenericCardLayout
      name={cardName}
      floor={<NoTranslate>{activeArea.name}</NoTranslate>}
      action={
        task.metadata?.downloadUrl
          ? {
              name: "Download",
              callback: downloadExportedVolume,
            }
          : undefined
      }
    >
      {isBackgroundTaskActive(task.state) && (
        <ProgressCardProgress label="Export" task={task} />
      )}
    </GenericCardLayout>
  );
}

type ToAlignCardProps = {
  /** ID of the LaserScan Section of the PointCloud to align */
  iElementID: GUID;
};

/** @returns the upload menu card to ask the user to align a processed point cloud */
export function ToAlignCard({ iElementID }: ToAlignCardProps): JSX.Element {
  const iElement = useAppSelector(selectIElement(iElementID));
  assert(
    iElement,
    "a ToAlignCard need to be created on an existing and loaded IElement",
  );
  const store = useAppStore();
  const dispatch = useAppDispatch();
  const activeArea = useAppSelector(selectActiveArea(iElement));
  const isCad = isIElementBimModelSection(iElement);

  const disableAlignment = useDisableCaptureTreeAlignment(activeArea);

  const startAlignment = useCallback(() => {
    const state = store.getState();
    const elementToAlign = selectChildDepthFirst(
      iElement,
      isIElementGenericStream,
    )(state);

    let dataSession;

    if (
      !isCad &&
      elementToAlign &&
      isIElementGenericPointCloudStream(elementToAlign)
    ) {
      const isAligned = selectIsPointCloudAligned(elementToAlign)(state);

      const numberOfAlignedPointClouds = selectNumberOfPointCloudsOnFloor(
        activeArea,
        true,
      )(state);
      Analytics.track<AlignPointCloudEventProperties>(
        EventType.alignPointCloud,
        {
          via: "context menu",
          alreadyAligned: isAligned,
          numberOfAlignedPCs: numberOfAlignedPointClouds,
        },
      );

      // currently that logic supports only data in the BI-tree.
      // we know that it's not compatible with SCENE data structure and new capture tree.
      // similar problem present in many workflows related to alignments.
      // Those problems will be addressed in the epic https://faro01.atlassian.net/browse/CADBIM-715
      dataSession = selectAncestor(
        elementToAlign,
        isIElementSectionDataSession,
      )(state);
    }

    assert(
      elementToAlign && activeArea,
      "should not be called when there is no cloud or area",
    );

    openAlignmentWizard({
      elementIdToAlign: dataSession ? dataSession.id : elementToAlign.id,
      dispatch,
    });
  }, [activeArea, dispatch, iElement, isCad, store]);

  const cardName = <NoTranslate>{iElement.name}</NoTranslate>;

  return (
    <>
      {isCad && (
        <GenericCardLayout
          name={cardName}
          floor="3D Model"
          icon={ElementIconType.ViewInArIcon}
        />
      )}

      {!isCad && (
        <GenericCardLayout
          name={cardName}
          floor={<NoTranslate>{activeArea?.name}</NoTranslate>}
          icon={ElementIconType.PointCloudIcon}
          action={{
            name: "Align",
            callback: startAlignment,
            disableMessage: disableAlignment ? compatibilityMessage : undefined,
          }}
        />
      )}
    </>
  );
}

type TaskCardProps = {
  /** Id of the task to show a task card for */
  taskID: GUID;
};

/** @returns the task menu card to notify the user of a background task */
export function TaskCard({ taskID }: TaskCardProps): JSX.Element {
  const task = useAppSelector(selectBackgroundTask(taskID));
  assert(task, "a TaskCard need to be created on an existing BackgroundTask");
  assert(
    isFileUploadTask(task) ||
      isProcessingTask(task) ||
      isGenericRegistrationTask(task),
    "a TaskCard expects a file upload task or a Processing task",
  );

  if (task.state === BackgroundTaskState.failed) {
    return <ErrorCard task={task} />;
  }

  switch (task.type) {
    case "FileUpload":
      return <UploadTaskCard task={task} />;
    case ProgressApiSupportedTaskTypes.c2cRegistration:
    case ProgressApiSupportedTaskTypes.registerMultiCloudDataSet:
    case ProgressApiSupportedTaskTypes.mergePointClouds:
      return <RegistrationTaskCard task={task} />;
    default:
      return <ProcessingTaskCard task={task} />;
  }
}

type BackgroundTaskCardProps<Task extends BackgroundTask = BackgroundTask> = {
  /** Task to show the progress state of in the upload menu card */
  task: Task;
};

/** @returns the upload menu card to track a PointCloud upload */
export function UploadTaskCard({
  task,
}: BackgroundTaskCardProps<FileUploadTask>): JSX.Element {
  const cancelUpload = useCancelUpload();
  const refElement = useAppSelector((state) =>
    task.iElementId ? selectIElement(task.iElementId)(state) : undefined,
  );
  const activeArea = useAppSelector(selectActiveAreaOrThrow(refElement));

  const icon = isSupportedCADFileExtension(task.metadata.filename)
    ? ElementIconType.ViewInArIcon
    : ElementIconType.PointCloudIcon;

  return (
    <ProgressCardLayout
      name={task.metadata.filename}
      floor={<NoTranslate>{activeArea.name}</NoTranslate>}
      upload={task}
      icon={icon}
      menu={[
        {
          name: "Cancel import",
          callback: () => cancelUpload(task.id),
          color: "error",
        },
      ]}
    />
  );
}

/**
 * @returns The name of an iElement. If the iElement is not yet in the store,
 * it's fetched from the ProjectAPI
 * @param id The id of the iElement
 */
function useElementName(id: GUID | null): string | undefined {
  const { projectApiClient } = useApiClientContext();
  const [name, setName] = useState<string>();

  const element = useAppSelector(selectIElement(id));
  useEffect(() => {
    async function fetchName(): Promise<string | undefined> {
      if (!id) return;

      const elements = await projectApiClient.getAllIElements({
        ids: [id],
      });

      setName(elements.at(0)?.name);
    }

    if (element) {
      setName(element.name);
      return;
    }

    fetchName();
  }, [element, id, projectApiClient]);

  return name;
}

/** @returns the upload menu card to track a backend processing of a PointCloud or CAD/Bim */
function ProcessingTaskCard({
  task,
}: BackgroundTaskCardProps<ProcessingTask>): JSX.Element {
  const element = useAppSelector(selectIElement(task.iElementId ?? ""));
  const activeArea = useAppSelector(selectActiveArea(element));

  const elementName = useElementName(task.iElementId);

  const name = useMemo(() => {
    if (elementName) {
      return elementName;
    }

    // Use custom name for tasks that do not have a reference id
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return "SCENE conversion";
    }
    if (task.type === ProgressApiSupportedTaskTypes.pointCloudLazToPotree) {
      return "Scan processing";
    }

    return "Unknown";
  }, [elementName, task.type]);

  const icon = useMemo(() => {
    switch (task.type) {
      case ProgressApiSupportedTaskTypes.videoMode:
        return ElementIconType.VideoCameraIcon;
      case ProgressApiSupportedTaskTypes.bimModelImport:
        return ElementIconType.ViewInArIcon;
      default:
        return ElementIconType.PointCloudIcon;
    }
  }, [task.type]);

  return (
    <ProgressCardLayout
      name={name}
      // Avoid putting the floor name if the task does not reference an element
      floor={
        element ? <NoTranslate>{activeArea?.name}</NoTranslate> : undefined
      }
      processing={task}
      upload={null}
      icon={icon}
    />
  );
}

type ErrorCardProps<Task extends BackgroundTask = BackgroundTask> = {
  /** Id of the task to show an error card for */
  task: Task;
};

/**
 * @returns Dismissible card showing an error and the cause, if available
 */
function ErrorCard({ task }: ErrorCardProps): JSX.Element {
  const dispatch = useAppDispatch();
  const element = useAppSelector(selectIElement(task.iElementId ?? ""));
  const activeArea = useAppSelector(selectActiveAreaOrThrow(element));

  const dismissErrorTask = useCallback((): void => {
    dispatch(removeBackgroundTask(task.id));
  }, [dispatch, task.id]);

  const { taskName, errorCode } = getErrorCodeAndTaskName(task, element?.name);

  const elementName = useMemo(() => {
    if (element?.name) return <NoTranslate>{element.name}</NoTranslate>;
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return "SCENE conversion";
    }
  }, [task, element]);

  const errorMessage = useMemo(() => {
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) {
      return "Conversion failed. Save again the project in SCENE and press the Sync button to start the processing";
    }
    return taskErrorToUserMessage(taskName, errorCode);
  }, [errorCode, task.type, taskName]);

  const menu = useMemo<CardAction[]>(() => {
    if (task.type === ProgressApiSupportedTaskTypes.sceneConversion) return [];
    return [
      {
        name: "Clear",
        callback: dismissErrorTask,
      },
    ];
  }, [dismissErrorTask, task.type]);

  return (
    <GenericCardLayout
      name={elementName}
      // Avoid putting the floor name if the task does not reference an element
      floor={element ? <NoTranslate>{activeArea.name}</NoTranslate> : undefined}
      menu={menu}
    >
      <Typography
        color="red600"
        fontWeight={FontWeights.SemiBold}
        fontSize="0.75em"
      >
        {errorMessage}
      </Typography>
    </GenericCardLayout>
  );
}

type GenericCardLayoutProps = {
  /** Name of the uploaded file */
  name: ReactNode;

  /** Name of the target floor for this upload */
  floor?: ReactNode;

  /** Action to offer to the user inside the card */
  action?: CardAction;

  /** A menu with multiple actions */
  menu?: CardAction[];

  /** An icon to show before the name if available */
  icon?: ElementIconType;

  /** Optional style to apply the the wrapper of the title */
  titleWrapperSx?: SxProps<Theme>;
};

/** @returns the main layout of a menu card */
function GenericCardLayout({
  name,
  floor,
  action,
  menu,
  icon,
  titleWrapperSx,
  children,
}: PropsWithChildren<GenericCardLayoutProps>): JSX.Element {
  return (
    <Stack sx={{ p: 0.5, mx: 4 }}>
      <Stack
        direction="row"
        justifyContent="space-between"
        alignItems="center"
        gap={0.375}
        sx={titleWrapperSx}
      >
        {icon && <ElementIcon icon={icon} sx={{ fontSize: "1.125em" }} />}
        <TruncatedText fontSize="0.875em" color="darkGrey" flex="1">
          {name}
        </TruncatedText>

        {action && <CardActionButton {...action} />}
        {!!menu?.length && <CardActionMenu menu={menu} />}
      </Stack>

      {floor && (
        <Typography
          fontSize="0.75rem"
          color="darkGrey80"
          sx={{
            pb: 1.25,
          }}
        >
          {floor}
        </Typography>
      )}
      {!!children && (
        <Stack component="div" sx={{ pb: 1.5 }} gap={1}>
          {children}
        </Stack>
      )}
    </Stack>
  );
}

type ProgressCardLayoutProps = Omit<
  GenericCardLayoutProps,
  "titleWrapperSx"
> & {
  /** Upload task to show progress of, or null if upload is finished */
  upload?: BackgroundTask | null;

  /** Processing task to show the progress of or null if processing is finished */
  processing?: BackgroundTask | null;
};

/** @returns the main layout of an upload menu card */
function ProgressCardLayout({
  upload,
  processing,
  floor,
  ...rest
}: ProgressCardLayoutProps): JSX.Element {
  const expectedEnd = processing?.expectedEnd ?? upload?.expectedEnd;

  return (
    <GenericCardLayout
      floor={floor}
      // Add some space between the card's title and the progress bar
      titleWrapperSx={{ pb: floor ? 0.5 : TITLE_TO_PROGRESS_PADDING }}
      {...rest}
    >
      <ProgressCardProgress label="Uploading..." task={upload} />
      {processing && (
        <ProgressCardProgress label="Processing" task={processing} />
      )}

      <Stack
        direction="row"
        alignItems="center"
        sx={{
          fontSize: "0.8em",
          color: ({ palette }) => `${palette.darkGrey}E6`,
        }}
      >
        {upload === null ? (
          <Box
            component="span"
            sx={{
              color: ({ palette }) => `${palette.primary.main}E6`,
              fontWeight: FontWeights.SemiBold,
            }}
          >
            Uploaded.
          </Box>
        ) : (
          "Uploading..."
        )}
        &nbsp;
        <ProcessingStateLabel task={processing} />
        {expectedEnd && (
          <>
            &nbsp;(
            <RemainingTimeLabel
              expectedEnd={expectedEnd}
              sx={{
                color: ({ palette }) => `${palette.gray850}E6`,
                fontWeight: FontWeights.SemiBold,
              }}
            />
            )
          </>
        )}
      </Stack>
    </GenericCardLayout>
  );
}

type ProcessingStateLabelProps = {
  /** Task to show the progress state label of */
  task: BackgroundTask | null | undefined;
};

/**
 * @returns the label for a task progress if available
 */
function ProcessingStateLabel({
  task,
}: ProcessingStateLabelProps): string | null {
  if (!task) {
    return null;
  }
  switch (task.state) {
    case BackgroundTaskState.created:
      return "Task created...";
    case BackgroundTaskState.scheduled:
      return "Task scheduled...";
    case BackgroundTaskState.started:
      return "Now processing...";
    case BackgroundTaskState.succeeded:
      return "Task completed";
    case BackgroundTaskState.aborted:
      return "Task canceled";
    case BackgroundTaskState.failed:
      return "Task failed";
  }
}

type ProgressCardProgressProps = {
  /** Label for the progress notification */
  label: string;

  /** Task to show the progress of */
  task?: BackgroundTask | null;
};

/** @returns a label or a linear progress to notify a BackgroundTask state in the upload menu card */
function ProgressCardProgress({
  task,
}: ProgressCardProgressProps): JSX.Element | null {
  if (!task) {
    return null;
  }

  return (
    <LinearProgress
      value={task.progress}
      variant={task.progress > 0 ? "determinate" : "indeterminate"}
    />
  );
}

type CardAction = {
  /** Name of the action button */
  name: string;

  /** Color for the action button */
  color?: ButtonProps["color"];

  /** Callback executed when the action is triggered */
  callback(): void;

  /** Allow to disable to the action button by providing a message to show */
  disableMessage?: string;
};

/** @returns the card button to trigger an action */
function CardActionButton({
  name,
  callback,
  disableMessage,
}: CardAction): JSX.Element {
  return (
    <FaroTooltip title={disableMessage}>
      <FaroButton
        disabled={!!disableMessage}
        variant="ghost"
        sx={{ p: 0 }}
        onClick={callback}
      >
        {name}
      </FaroButton>
    </FaroTooltip>
  );
}

/** @returns a three dot menu for a card */
function CardActionMenu({
  menu,
}: Required<Pick<GenericCardLayoutProps, "menu">>): JSX.Element {
  const [isOpen, setIsOpen] = useState(false);
  const button = useRef<HTMLButtonElement>(null);

  return (
    <>
      <FaroIconButton ref={button} onClick={() => setIsOpen(true)} size="xs">
        <ThreeDotsIcon />
      </FaroIconButton>

      <Menu
        anchorEl={button.current}
        open={isOpen}
        onClose={() => setIsOpen(false)}
      >
        {menu.map(({ name, callback, color }) => (
          <MenuItem key={name} onClick={callback}>
            <Typography component="span" color={color}>
              {name}
            </Typography>
          </MenuItem>
        ))}
      </Menu>
    </>
  );
}
