import {
  EventType,
  ToggleOpacityPanelEventProperties,
} from "@/analytics/analytics-events";
import { TransparencySliders } from "@/components/common/transparency-sliders/transparency-sliders";
import { useAppDispatch, useAppSelector } from "@/store/store-hooks";
import {
  selectActiveSettingsMenu,
  selectActiveTool,
  selectToolVisibility,
} from "@/store/ui/ui-selectors";
import {
  ToolName,
  ToolVisibility,
  activateTool,
  setActiveSettingsMenu,
} from "@/store/ui/ui-slice";
import {
  Toolbar as FUIToolbar,
  ToolButton,
  ToolGroup,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  Box,
  Stack,
  ToggleButtonGroupProps,
  Tooltip,
  Typography,
} from "@mui/material";
import { Fragment, useMemo, useState } from "react";
import { useTransparencySettingsContext } from "../common/transparency-sliders/transparency-settings-context";

/** Possible content of the tool's tooltip. It can be either a text or a custom component. */
type TooltipContent = string | JSX.Element;

type ToolTooltipTextOptions = {
  /** Text or custom component, to show on hover when the button is visible */
  default: TooltipContent;

  /** Text or custom component, to show on hover when the button is disabled */
  disabled?: TooltipContent;

  /** Text or custom component, to show on hover when the tool is waiting for something */
  waiting?: TooltipContent;

  /** True, if the tooltip should be interactive */
  isInteractive?: boolean;
};

/** Definition of the representation of a tool within the Toolbar component */
type Tool = {
  /** Name of the corresponding tool */
  name: ToolName;

  /** Icon to show in the toolbar */
  icon: JSX.Element;

  /** Text to show, as a tooltip, on the tool's button */
  tooltip?: ToolTooltipTextOptions;

  /** An optional callback on click of the tool */
  onClick?(): void;

  /** An optional element that will be shown on the left of button of the active tool */
  subMenu?: JSX.Element;
};

export enum ToolStoreField {
  activeTool = "activeTool",
  activeSettingsMenu = "activeSettingsMenu",
}

export type ToolGroup = {
  /** A unique key for the tool group */
  key: string;

  /** Props that can be passed to toggle button group */
  groupProps?: ToggleButtonGroupProps;

  /** List of Tools which should be in the given tool group */
  tools: Tool[];

  /** Which store field should the tool group dispatch its active tool */
  storeField?: ToolStoreField;
};

interface Props {
  /** Groups of tools to be rendered */
  toolGroups: ToolGroup[];
}

/**
 * @returns a toolbar in which the buttons can be grouped
 */
export function Toolbar({ toolGroups }: Props): JSX.Element | null {
  const dispatch = useAppDispatch();
  const activeTool = useAppSelector(selectActiveTool);
  const toolsVisibility = useAppSelector(selectToolVisibility);

  const activeSettingsMenu = useAppSelector(selectActiveSettingsMenu);

  const isSettingsMenuOpen = activeSettingsMenu !== null;

  const objectsOpacity = useTransparencySettingsContext(
    (state) => state.objectsOpacity,
  );

  /** Filtering out the tools which are not visible */
  const filteredToolGroups = useMemo(
    () =>
      toolGroups
        .map((group) => ({
          ...group,
          tools: group.tools.filter(
            (tool) => toolsVisibility[tool.name] !== ToolVisibility.hidden,
          ),
        }))
        .filter((group) => group.tools.length > 0),
    [toolGroups, toolsVisibility],
  );

  // Get the sub tools for the current active tool
  const activeSubTools = useMemo(() => {
    for (const groups of filteredToolGroups) {
      for (const tool of groups.tools) {
        if (tool.name === activeTool) {
          return tool.subMenu;
        }
      }
    }
  }, [activeTool, filteredToolGroups]);

  // Compute the top position for the sub tool
  const [activeButton, setActiveButton] = useState<HTMLButtonElement>();
  const [stack, setStack] = useState<HTMLDivElement>();
  const top = useMemo(() => {
    if (!activeButton || !stack) return;
    return (
      activeButton.getBoundingClientRect().top -
      stack.getBoundingClientRect().top
    );
  }, [activeButton, stack]);

  return (
    <Box
      component="div"
      sx={{
        backdropFilter: "blur(4px) brightness(40%)",
        borderRadius: 1.5,
      }}
    >
      <Stack direction="row" ref={(el: HTMLDivElement) => setStack(el)}>
        {activeSettingsMenu === ToolName.opacity && <TransparencySliders />}
        <div
          style={{
            position: "absolute",
            top,
            transform: "translate(-110%, 0%)",
          }}
        >
          {activeSubTools}
        </div>
        <FUIToolbar
          sx={{
            backdropFilter: "none",
            borderLeft: isSettingsMenuOpen
              ? ({ palette }) => `1px solid ${palette.white}66`
              : null,
          }}
        >
          {filteredToolGroups.map(
            ({
              key,
              groupProps,
              tools,
              storeField = ToolStoreField.activeTool,
            }) => (
              <Fragment key={key}>
                <ToolGroup
                  {...groupProps}
                  value={
                    storeField === ToolStoreField.activeTool
                      ? activeTool
                      : activeSettingsMenu
                  }
                  exclusive
                  onChange={(_, value: ToolName) => {
                    const isDisabled =
                      toolsVisibility[value] === ToolVisibility.disabled ||
                      toolsVisibility[value] === ToolVisibility.waiting;

                    if (!isDisabled) {
                      if (storeField === ToolStoreField.activeTool) {
                        trackToolActivation(value);

                        dispatch(activateTool(value));
                      } else {
                        if (
                          value === ToolName.opacity ||
                          activeSettingsMenu === ToolName.opacity
                        ) {
                          Analytics.track<ToggleOpacityPanelEventProperties>(
                            EventType.toggleOpacityPanel,
                            {
                              isOpened: value === ToolName.opacity,
                              currentCadOpacity: objectsOpacity.cadOpacity,
                              currentPointCloudOpacity:
                                objectsOpacity.pcOpacity,
                              currentMapOpacity: objectsOpacity.mapOpacity,
                            },
                          );
                        }

                        dispatch(setActiveSettingsMenu(value));
                      }
                    }
                  }}
                >
                  {tools.map(({ name, icon, tooltip, onClick }) => {
                    if (toolsVisibility[name] === ToolVisibility.hidden) {
                      return null;
                    }

                    const isDisabled =
                      toolsVisibility[name] === ToolVisibility.disabled ||
                      toolsVisibility[name] === ToolVisibility.waiting;

                    // Create the tool's button component.
                    const toolButton = (
                      <ToolButton
                        showSpinner={
                          toolsVisibility[name] === ToolVisibility.waiting
                        }
                        key={name}
                        value={name}
                        aria-label={name}
                        selected={
                          (activeTool === name ||
                            activeSettingsMenu === name) &&
                          !isDisabled
                        }
                        ref={
                          activeTool === name
                            ? (el: HTMLButtonElement) => setActiveButton(el)
                            : undefined
                        }
                        disabled={isDisabled}
                        sx={{
                          ...(activeSettingsMenu === name && {
                            borderBottomLeftRadius: 0,
                            borderTopLeftRadius: 0,
                          }),
                        }}
                        onClick={onClick}
                      >
                        {icon}
                      </ToolButton>
                    );

                    // If the tool has a tooltip, then wrap the button with the Tooltip component.
                    return tooltip ? (
                      <Tooltip
                        key={name}
                        title={findBestTooltipContent(
                          tooltip,
                          toolsVisibility[name],
                        )}
                        placement={
                          activeSubTools && name === activeTool
                            ? "bottom"
                            : "left"
                        }
                        disableInteractive={!tooltip.isInteractive}
                      >
                        {toolButton}
                      </Tooltip>
                    ) : (
                      toolButton
                    );
                  })}
                </ToolGroup>
              </Fragment>
            ),
          )}
        </FUIToolbar>
      </Stack>
    </Box>
  );
}

/**
 * @param tooltip object containing all the available tooltip content for the tool
 * @param visibility the visibility status of the tool
 * @returns the tooltip content based on the state of the button and the available tooltips.
 */
function findBestTooltipContent(
  tooltip: ToolTooltipTextOptions,
  visibility: ToolVisibility,
): JSX.Element {
  let tooltipContent = tooltip.default;

  // If the button is disabled and the tooltip object has a specific tooltip for the disabled button
  // then use the specific tooltip.
  if (
    visibility === ToolVisibility.disabled &&
    tooltip.disabled !== undefined
  ) {
    tooltipContent = tooltip.disabled;
  }

  if (visibility === ToolVisibility.waiting && tooltip.waiting !== undefined) {
    tooltipContent = tooltip.waiting;
  }

  // If the chosen content is a string, then wrap it in a Typography to adjust its style.
  return typeof tooltipContent === "string" ? (
    <Typography>{tooltipContent}</Typography>
  ) : (
    tooltipContent
  );
}

/**
 * Tracking the activation of the individual tools
 *
 * @param toolName The name of the tool that has been activated
 */
function trackToolActivation(toolName: ToolName): void {
  switch (toolName) {
    case ToolName.annotation:
      Analytics.track(EventType.triggerAnnotationCreationTool);
  }
}
