import { ElementIcon, iconForElement } from "@/components/ui/icons";
import { selectDefaultModeFor } from "@/modes/index";
import { ModeNames } from "@/modes/mode";
import { useAppSelector } from "@/store/store-hooks";
import { Dropdown, NoTranslate } from "@faro-lotv/flat-ui";
import { convertToDateTimeString } from "@faro-lotv/foundation";
import { GUID, IElement, IElementBase } from "@faro-lotv/ielement-types";
import {
  newestToOldest,
  selectIElementChildren,
} from "@faro-lotv/project-source";
import {
  FormControl,
  SelectChangeEvent,
  Stack,
  Typography,
} from "@mui/material";
import { isEqual } from "lodash";
import { useCallback, useMemo, useState } from "react";

type TimestampDropdownOptionLabelProps = {
  /** an IElement whose label needs to be shown in the dropdown */
  iElement: IElement;

  /** a formatter function which lets the label to be customised */
  labelFormatter?(iElement: IElement): JSX.Element | string;
};

function TimestampDropdownOptionLabel({
  iElement,
  labelFormatter,
}: TimestampDropdownOptionLabelProps): JSX.Element {
  // Reformat the time to a localized, readable string
  const dateString = convertToDateTimeString(iElement.createdAt);

  const childrenTypeHints = useAppSelector(
    selectIElementChildren(iElement.id),
    isEqual,
  ).map((el: IElementBase) => el.typeHint);

  // Specific icon to display for the current node
  const icon = useMemo(
    () => iconForElement(iElement.type, iElement.typeHint, childrenTypeHints),
    [iElement.type, iElement.typeHint, childrenTypeHints],
  );

  const [date, time] = dateString.split(",");
  return (
    <Stack component="span" direction="row" alignItems="center">
      <ElementIcon
        sx={{
          width: "18px",
          height: "18px",
          lineHeight: 0,
        }}
        icon={icon}
      />
      {labelFormatter ? (
        <Typography
          component="span"
          sx={{
            fontSize: "0.875rem",
            ml: 1,
            textOverflow: "ellipsis",
            overflow: "hidden",
          }}
          noWrap
        >
          {labelFormatter(iElement)}
        </Typography>
      ) : (
        <NoTranslate>
          <Typography sx={{ fontSize: "0.875rem", ml: 1 }}>{date}</Typography>
          <Typography sx={{ fontSize: "0.875rem", mx: 1.5 }}>{time}</Typography>
        </NoTranslate>
      )}
    </Stack>
  );
}

type TimestampDropdownBaseProps = {
  /** ID of the selected option */
  value: GUID;

  /** List of options */
  options: IElement[];

  /** Custom function to format the label as required */
  labelFormatter?(iElement: IElement): JSX.Element | string;

  /** List of id of the elements to disable in the drop-down menu */
  disabledElements?: GUID[];

  /** Callback to signal a new option was selected */
  onChange(ev: SelectChangeEvent<GUID>): void;
};

/**
 * @returns a base dropdown component for timestamp selections
 */
export function TimestampDropdownBase({
  value,
  options,
  labelFormatter,
  disabledElements = [],
  onChange,
}: TimestampDropdownBaseProps): JSX.Element {
  const [focused, setFocused] = useState(false);

  // Sort the options from newest to oldest
  const sortedOptions = useMemo(() => options.sort(newestToOldest), [options]);

  const onClose = useCallback(() => {
    setFocused(false);
  }, [setFocused]);

  const onOpen = useCallback(() => {
    setFocused(true);
  }, [setFocused]);

  // Compute the default mode for each value in the dropdown
  const modeComputedOptions: Array<IElement & { mode: ModeNames | undefined }> =
    useAppSelector(
      (state) =>
        sortedOptions.map((option) => ({
          ...option,
          mode: selectDefaultModeFor(option)(state)?.targetMode,
        })),
      (oldValue, newValue) => isEqual(oldValue, newValue),
    );

  return (
    <FormControl
      focused={focused}
      sx={{ flexShrink: 0, flexGrow: 0, display: "inline" }}
    >
      <Dropdown
        dark
        value={value}
        onClose={onClose}
        onOpen={onOpen}
        variant="outlined"
        disabled={options.length < 2}
        onChange={onChange}
        options={modeComputedOptions.map((option) => ({
          key: option.id,
          value: option.id,
          label: (
            <TimestampDropdownOptionLabel
              iElement={option}
              labelFormatter={labelFormatter}
            />
          ),
          isDisabled: !option.mode || disabledElements.includes(option.id),
        }))}
        sx={{
          height: "2.25rem",
          width: "300px",
          ".MuiSelect-outlined.MuiOutlinedInput-input": {
            fontSize: "1em",
            p: 1.5,
            pr: 4,
          },
        }}
      />
    </FormControl>
  );
}
