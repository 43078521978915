/** ScanToBim Storage Uri. */
/* TODO: This link will be replaced soon by one provided via cluster config see: https://faro01.atlassian.net/browse/S2BIM-315 */
const scanToBimStorageUri =
  "https://holobuildercom.blob.core.windows.net/scantobim";

/** SendToRevit plugin installer info */
type RevitPluginInstallerInfo = {
  /** version of the installer */
  version: string;
  /** installer filename */
  fileName: string;
};

/**
 * TypeGuard for RevitPluginInstallerInfo
 *
 * @param obj object to check
 * @returns true if obj matches the type RevitPluginInstallerInfo
 */
function isRevitPluginInstallerInfo(
  obj: Record<string, unknown>,
): obj is RevitPluginInstallerInfo {
  return typeof obj.version === "string" && typeof obj.fileName === "string";
}

/** (All) Available Revit Installer Info type */
type AvailableRevitPluginInstallerInfo = {
  /** version of the latest SendToRevit plugin installer */
  latest: string;
  /** array containing all available RevitPluginInstallers */
  availableVersions: RevitPluginInstallerInfo[];
};

/**
 * Type guard for AvailableRevitInstallerInfo
 *
 * @param obj object to check
 * @returns true if obj matches the type AvailableRevitInstallerInfo
 */
function isAvailableRevitInstallerInfo(
  obj: Record<string, unknown>,
): obj is AvailableRevitPluginInstallerInfo {
  return (
    typeof obj.latest === "string" &&
    Array.isArray(obj.availableVersions) &&
    isRevitPluginInstallerInfo(obj.availableVersions[0])
  );
}

/**
 * Query latest Revit Installer link.
 *
 * @returns Revit Installer link
 */
export async function queryLatestRevitInstallerLink(): Promise<string> {
  const res = await fetch(
    `${scanToBimStorageUri}/availableRevitInstallerInfo.json`,
  );

  if (!res.ok) throw new Error("Unable to fetch Revit Installer Info");

  const response = await res.json();

  if (!isAvailableRevitInstallerInfo(response)) {
    throw new Error("Fetched Revit Installer Info is invalid");
  }

  const latestInstaller = response.availableVersions.find(
    (info) => info.version === response.latest,
  );

  if (!latestInstaller) {
    throw new Error("Fetched Revit Installer Info is invalid");
  }

  return `${scanToBimStorageUri}/${latestInstaller.fileName}`;
}
