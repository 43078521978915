import { GUID } from "@faro-lotv/ielement-types";
import { createSlice, PayloadAction } from "@reduxjs/toolkit";
import { Vector3Tuple } from "three";

type SendToPoint = {
  /** The unique id of this SendTo point */
  id: GUID;
  /** The coordinates of the SendTo point */
  point: Vector3Tuple;
  /** The camera position at the time when the point had been picked */
  camPos: Vector3Tuple;
};

type SendToToolState = {
  /** For each specific element, identified by its GUID, we store the list of SendTo points associated to it */
  sendToPoints: Record<GUID, SendToPoint[]>;
};

const initialState: SendToToolState = {
  sendToPoints: {},
};

const sendToToolSlice = createSlice({
  name: "sendToTool",
  initialState,
  reducers: {
    addSendToPoint(
      state,
      action: PayloadAction<{ elementID: GUID; sendToPoint: SendToPoint }>,
    ) {
      if (!(action.payload.elementID in state.sendToPoints)) {
        state.sendToPoints[action.payload.elementID] = [];
      }

      state.sendToPoints[action.payload.elementID].push(
        action.payload.sendToPoint,
      );
    },
  },
});

export const sendToToolReducer = sendToToolSlice.reducer;

export const { addSendToPoint } = sendToToolSlice.actions;
