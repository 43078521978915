import {
  NdrParams,
  Open3dIcpParams,
  RegistrationAlgorithm,
  TvbParams,
} from "@faro-lotv/service-wires";
import { PayloadAction, createSlice } from "@reduxjs/toolkit";

type AlignmentParametersState = {
  /** Selected registration algorithm for refinement */
  registrationAlgorithm: RegistrationAlgorithm;

  /** Stored parameters for ndr algorithm */
  ndrParams: NdrParams;

  /** Stored parameters for icp algorithm */
  icpParams: Open3dIcpParams;

  /** Stored parameters for tvb algorithm */
  tvbParams: TvbParams;
};

/** Default values for the parameters for ndr algorithm */
export const NDR_DEFAULT = {
  voxel_size: 0.03,
  tvb: false,
  cascade_voxel_size: false,
  use_large_pc_as_reference: true,
};

/** Default values for the parameters for icp algorithm */
export const ICP_DEFAULT = {
  voxel_size: 0.1,
  max_iterations: 1000,
  relative_error: 0.00001,
  factor_threshold: 5,
  auto_voxel_size: true,
  auto_factor_threshold: true,
  cascade_voxel_size: true,
  use_large_pc_as_reference: true,
};

/** Default values for the parameters for TVB algorithm */
export const TVB_DEFAULT = {
  voxel_size: 0.05,
  cascade_voxel_size: false,
  use_large_pc_as_reference: true,
};

export const initialState: Readonly<AlignmentParametersState> = Object.freeze({
  registrationAlgorithm: RegistrationAlgorithm.Open3dIcp,
  ndrParams: NDR_DEFAULT,
  icpParams: ICP_DEFAULT,
  tvbParams: TVB_DEFAULT,
});

export const registrationParametersSlice = createSlice({
  initialState,
  name: "registrationParameters",

  reducers: {
    /**
     * Set the registration algorithm for the refinement
     *
     * @param state The current state
     * @param action The selected registration algorithm
     */
    setRegistrationAlgorithm(
      state,
      action: PayloadAction<RegistrationAlgorithm>,
    ) {
      state.registrationAlgorithm = action.payload;
    },

    /**
     * Set the parameter-set for ICP
     *
     * @param state The current state
     * @param action ICP parameters
     */
    setIcpParameters(state, action: PayloadAction<Open3dIcpParams>) {
      state.icpParams = action.payload;
    },
    /**
     * Set the parameter-set for NDR
     *
     * @param state The current state
     * @param action NDR parameters
     */
    setNdrParameters(state, action: PayloadAction<NdrParams>) {
      state.ndrParams = action.payload;
    },
    /**
     * Set the parameter-set for TVB
     *
     * @param state The current state
     * @param action TVB parameters
     */
    setTvbParameters(state, action: PayloadAction<TvbParams>) {
      state.tvbParams = action.payload;
    },
  },
});

export const {
  setRegistrationAlgorithm,
  setIcpParameters,
  setNdrParameters,
  setTvbParameters,
} = registrationParametersSlice.actions;

export const registrationParametersReducer =
  registrationParametersSlice.reducer;
