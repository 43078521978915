import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { PointCloudObject } from "@/object-cache";
import {
  PointCloudAnalysis,
  setAnalysisVisibility,
} from "@/store/point-cloud-analysis-tool-slice";
import { useAppDispatch } from "@/store/store-hooks";
import { CollapsedAnalysisRenderer } from "./collapsed-analysis-renderer";
import { ColormapAnalysisRenderer } from "./colormap-analysis-renderer";

type AnalysesRendererProps = {
  /** The current active point cloud */
  pointCloud: PointCloudObject;

  /** The list of analyses to render */
  analyses: PointCloudAnalysis[];
};

/** @returns The renderer of the analyses stored in the app store */
export function AnalysesRenderer({
  analyses,
  pointCloud,
}: AnalysesRendererProps): JSX.Element {
  const labelContainer = useViewOverlayRef();
  const dispatch = useAppDispatch();

  return (
    <>
      {analyses.map((analysis) => {
        if (analysis.parentId !== pointCloud.iElement.id) {
          return null;
        }
        return (
          <>
            {analysis.isVisible && (
              <ColormapAnalysisRenderer
                key={analysis.id}
                pointCloud={pointCloud}
                analysis={analysis}
              />
            )}

            <CollapsedAnalysisRenderer
              visible={!analysis.isVisible}
              polygonSelection={analysis.polygonSelection}
              parentRef={labelContainer}
              onClick={() => {
                dispatch(
                  setAnalysisVisibility({
                    analysisId: analysis.id,
                    visible: true,
                  }),
                );
              }}
            />
          </>
        );
      })}
    </>
  );
}
