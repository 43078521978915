import { EventType } from "@/analytics/analytics-events";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useAppDispatch } from "@/store/store-hooks";
import { deactivateTool } from "@/store/ui/ui-slice";
import { Point3, SendToRevit } from "@faro-asbuilt/send-to-plugin";
import {
  Checkmark4Icon,
  ExchangeArrowsIcon,
  FaroLogoCompactIcon,
  RevitIcon,
  SendToIcon,
  useDialog,
} from "@faro-lotv/flat-ui";
import { Analytics } from "@faro-lotv/foreign-observers";
import {
  Box,
  Divider,
  Link,
  List,
  ListItem,
  ListItemIcon,
  ListItemText,
  Paper,
  Stack,
  Typography,
} from "@mui/material";
import { nanoid } from "@reduxjs/toolkit";
import { PropsWithChildren, useCallback, useState } from "react";
import { queryLatestRevitInstallerLink } from "./query-latest-revit-installer-link";
import { SendToRevitContext } from "./send-to-revit-context";

/**
 * @returns A list item with a checkmark icon in front of its text. The props children are inserted into a ListTextItem.
 */
function CheckmarkListItem({ children }: PropsWithChildren): JSX.Element {
  return (
    <ListItem disableGutters>
      <ListItemIcon>
        <Checkmark4Icon />
      </ListItemIcon>
      <ListItemText sx={{ ml: -2.5, alignItems: "center" }}>
        {children}
      </ListItemText>
    </ListItem>
  );
}

/**
 * A small wrapper around createDialog to provide a customized SendToRevit Connection dialog with specific information
 * on how to establish a connection to Revit.
 *
 * @returns a promise with a boolean value, which is true if the modal SendToRevit connection dialog has been confirmed
 */
export function useSendToRevitConnectionDialog(): () => Promise<boolean> {
  const { createDialog } = useDialog();

  const connectionErrorDialog = useCallback(async () => {
    const latestInstallerLink = await queryLatestRevitInstallerLink();

    const hasAccepted = await createDialog({
      title: "",
      confirmText: "Got it",
      content: (
        <Stack>
          <Box component="div" sx={{ m: 2 }}>
            <Stack
              direction="row"
              alignItems="center"
              justifyContent="center"
              spacing={1}
            >
              <Paper>
                <FaroLogoCompactIcon
                  sx={{ m: 2, width: "80px", height: "80px" }}
                />
              </Paper>
              <ExchangeArrowsIcon
                sx={{ width: "50px", height: "50px", color: "gray500" }}
              />
              <Paper>
                <RevitIcon sx={{ m: 2, width: "80px", height: "80px" }} />
              </Paper>
            </Stack>

            <Typography
              align="center"
              variant="h5"
              sx={{
                m: 1,
                fontWeight: "bold",
              }}
            >
              Connect Sphere to Revit
            </Typography>

            <Typography align="center">
              To use the SendTo function you have to establish
              <br />
              the connection between the Sphere Viewer and Autodesk Revit®.
            </Typography>
          </Box>
          <Divider variant="middle" />
          <Box component="div" sx={{ mt: 2 }}>
            <Typography
              sx={{
                fontWeight: "bold",
              }}
            >
              First time using SendTo
            </Typography>
            <List disablePadding>
              <CheckmarkListItem>
                Install the{" "}
                <Link
                  href={latestInstallerLink}
                  download
                  target="_blank"
                  rel="noopener noreferrer"
                >
                  Revit Add-In
                </Link>
              </CheckmarkListItem>
              <CheckmarkListItem>Open Autodesk Revit®</CheckmarkListItem>
              <CheckmarkListItem>
                Go to the Scan To BIM ribbon tab and click SendTo button{" "}
                <SendToIcon
                  sx={{
                    position: "relative",
                    top: "5px",
                    boxShadow: 1,
                    borderRadius: 2,
                  }}
                />
              </CheckmarkListItem>
              <CheckmarkListItem>Connection established</CheckmarkListItem>
              <CheckmarkListItem>Ready to use SendTo</CheckmarkListItem>
            </List>
          </Box>
        </Stack>
      ),
      size: "s",
      variant: "info",
      showCancelButton: false,
    });
    Analytics.track(EventType.confirmSendToMessage);
    return hasAccepted;
  }, [createDialog]);
  return connectionErrorDialog;
}

/**
 * Provides the SendToRevit Context.
 *
 * @returns SendToRevit Provider
 */
export function SendToRevitProvider({
  children,
}: PropsWithChildren): JSX.Element {
  const [sendToRevit, setSendToRevit] = useState<SendToRevit | undefined>(
    undefined,
  );

  const [isConnected, setIsConnected] = useState(false);

  const sendPoint = useCallback(
    (id: string, point: Point3, cameraPosition: Point3) =>
      sendToRevit?.sendPoint(id, point, cameraPosition, nanoid()),
    [sendToRevit],
  );

  const sendCommand = useCallback(
    (command: string) => sendToRevit?.sendCommand(command, nanoid()),
    [sendToRevit],
  );

  const { handleErrorWithDialog } = useErrorHandlers();

  const dispatch = useAppDispatch();

  const connectionErrorDialog = useSendToRevitConnectionDialog();

  const connect = useCallback(() => {
    if (sendToRevit) {
      return;
    }

    const wsRevit = new SendToRevit();

    wsRevit.onopen = () => {
      // after connection is established check if protocol version is compatible
      wsRevit.sendProtocolVersionRequest(nanoid()).then((result) => {
        if (result.version === wsRevit.protocolVersion) {
          setIsConnected(true);
        } else {
          // report error and close connection
          const error = `SendTo Frontend version is ${wsRevit.protocolVersion}, SendTo Revit Version is ${result.version}. Please install the latest SendTo Revit plugin.`;

          const STATUS_POLICY_VIOLATION = 1000;
          wsRevit.close(STATUS_POLICY_VIOLATION, error);

          handleErrorWithDialog({
            title: "SendTo",
            error,
          });
        }
      });
    };

    wsRevit.onclose = () => {
      dispatch(deactivateTool());
      setSendToRevit(undefined);
      setIsConnected(false);
    };

    wsRevit.onerror = () => {
      dispatch(deactivateTool());
      setSendToRevit(undefined);
      connectionErrorDialog();
      setIsConnected(false);
    };

    setSendToRevit(wsRevit);
  }, [connectionErrorDialog, dispatch, handleErrorWithDialog, sendToRevit]);

  const disconnect = useCallback(() => {
    if (sendToRevit) {
      if (sendToRevit.isConnected()) {
        sendToRevit.close();
      }
      setSendToRevit(undefined);
    }
  }, [sendToRevit]);

  return (
    <SendToRevitContext.Provider
      value={{
        sendPoint: sendToRevit ? sendPoint : undefined,
        sendCommand: sendToRevit ? sendCommand : undefined,
        connect,
        disconnect,
        isConnected,
      }}
    >
      {children}
    </SendToRevitContext.Provider>
  );
}
