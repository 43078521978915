import { useFileUploaderWithPromise } from "@/components/common/file-upload-context/use-file-uploader";
import { UploadElementType } from "@/components/common/point-cloud-file-upload-context/use-upload-element";
import { MultiPointRenderer } from "@/components/r3f/renderers/measurements/multi-point-measure-renderer";
import { TwoPointMeasureSegment } from "@/components/r3f/renderers/measurements/two-point-segment-renderer";
import { useErrorHandlers } from "@/errors/components/error-handling-context";
import { useProjectUnitOfMeasure } from "@/hooks/use-unit-of-measure";
import { useViewOverlayRef } from "@/hooks/use-view-overlay-ref";
import { useCurrentArea } from "@/modes/mode-data-context";
import { PointCloudObject } from "@/object-cache";
import {
  addAnalysis,
  ReferencePlaneType,
  setActiveAnalysis,
  setIsAnalysisBeingCreated,
} from "@/store/point-cloud-analysis-tool-slice";
import {
  useAppDispatch,
  useAppSelector,
  useAppStore,
} from "@/store/store-hooks";
import { selectActiveTool } from "@/store/ui/ui-selectors";
import { deactivateTool, ToolName } from "@/store/ui/ui-slice";
import { assert, generateGUID } from "@faro-lotv/foundation";
import { getLotvMath, selectPointsByPolygon } from "@faro-lotv/lotv";
import { selectProjectId } from "@faro-lotv/project-source";
import { useApiClientContext } from "@faro-lotv/service-wires";
import { ThreeEvent, useThree } from "@react-three/fiber";
import { forwardRef, useCallback, useRef, useState } from "react";
import { MOUSE, Plane, Vector3 } from "three";
import { createAnalysisJsonFile } from "./analysis-saving-utils";
import {
  MultiPointMeasureControls,
  MultiPointMeasureControlsActions,
} from "./multi-point-measures/multi-point-measures-controls";
import { ProjectionType } from "./multi-point-measures/multi-point-measures-controls-logic";
import { ToolControlsRef } from "./tool-controls-interface";

type PointCloudAnalysisToolProps = {
  /** The active point cloud object */
  activePointCloud: PointCloudObject;
};

export const PointCloudAnalysisTool = forwardRef<
  ToolControlsRef,
  PointCloudAnalysisToolProps
>(function PointCloudAnalysisTool(
  { activePointCloud }: PointCloudAnalysisToolProps,
  ref,
): JSX.Element {
  const { handleErrorWithToast } = useErrorHandlers();
  const dispatch = useAppDispatch();

  const [currentPoint, setCurrentPoint] = useState<Vector3>();
  const onCurrentPointChanged = useCallback((point: Vector3 | undefined) => {
    setCurrentPoint(point ? point.clone() : undefined);
  }, []);

  const [polygonPoints, setPolygonPoints] = useState<Vector3[]>();
  const onPointsChanged = useCallback(
    async (points: Vector3[] | undefined) => {
      setPolygonPoints(
        await adjustPointsToBeCoplanar(points, activePointCloud),
      );
      // Reset the current point to trigger a redraw of all segments
      setCurrentPoint(currentPoint);
    },
    [activePointCloud, currentPoint],
  );

  const { camera } = useThree();
  const { area } = useCurrentArea();
  const appStore = useAppStore();
  const { coreApiClient } = useApiClientContext();
  const uploadFile = useFileUploaderWithPromise();

  const projectId = useAppSelector(selectProjectId);
  assert(projectId, "Project ID can not be undefined");

  const onAnalysisCompleted = useCallback(
    async (isClosed: boolean, iElementId: string) => {
      assert(isClosed, "PointCloudAnalysisTool only accept closed polygon");
      if (!polygonPoints) return;
      if (activePointCloud.iElement.id !== iElementId) return;

      const cameraDirection = new Vector3();
      camera.getWorldDirection(cameraDirection);

      // create new analysis
      const analysis = {
        id: generateGUID(),
        polygonSelection: polygonPoints.map((p) => p.toArray()),
        tolerance: 0.005,
        parentId: iElementId,
        referencePlaneType: ReferencePlaneType.flatness,
        cameraWorldDirection: cameraDirection.toArray(),
        isVisible: true,
      };

      try {
        const file = createAnalysisJsonFile(
          appStore.getState(),
          analysis,
          area,
        );

        const { downloadUrl } = await uploadFile({
          file,
          coreApiClient,
          uploadElementType: UploadElementType.none,
          projectId,
          silent: true,
        });

        assert(downloadUrl, "Expected download url to be defined");

        // NEXT...
        // Apply AddAnalysis mutation will be done in https://faro01.atlassian.net/browse/CADBIM-905
        // and https://faro01.atlassian.net/browse/CADBIM-907
      } catch (error) {
        handleErrorWithToast({ title: "Failed to upload analysis", error });
      }

      dispatch(
        addAnalysis({
          pointCloudID: iElementId,
          analysis,
        }),
      );

      setPolygonPoints(undefined);

      // set the new analysis as active
      dispatch(setActiveAnalysis(analysis));
      dispatch(setIsAnalysisBeingCreated(false));

      // Deactivate the tool after the analysis is completed.
      dispatch(deactivateTool());
    },
    [
      activePointCloud.iElement.id,
      appStore,
      area,
      camera,
      coreApiClient,
      dispatch,
      handleErrorWithToast,
      polygonPoints,
      projectId,
      uploadFile,
    ],
  );

  const onAnalysisStarted = useCallback(() => {
    dispatch(setIsAnalysisBeingCreated(true));
  }, [dispatch]);

  const onAnalysisCanceled = useCallback(() => {
    setPolygonPoints(undefined);
    dispatch(setIsAnalysisBeingCreated(false));
  }, [dispatch]);

  const { unitOfMeasure } = useProjectUnitOfMeasure();
  const controlActions = useRef<MultiPointMeasureControlsActions>(null);
  const onHandlerClicked = useCallback(
    (ev: ThreeEvent<MouseEvent>, index: number) => {
      if (!polygonPoints) return;
      if (index < 0 || index >= polygonPoints.length) return;
      if (polygonPoints.length < 3) return;
      if (ev.button !== MOUSE.LEFT) return;

      if (index === 0 || index === polygonPoints.length - 1) {
        controlActions.current?.completeMeasurement(true);
        ev.stopPropagation();
      }
    },
    [polygonPoints],
  );

  const labelContainer = useViewOverlayRef();

  const analysisActive = useAppSelector(selectActiveTool) === ToolName.analysis;

  return (
    <>
      {polygonPoints && polygonPoints.length >= 1 && currentPoint && (
        <TwoPointMeasureSegment
          visible
          start={currentPoint}
          end={polygonPoints[0]}
          labelPosition={currentPoint}
          length={currentPoint.distanceTo(polygonPoints[0])}
          index={0}
          main
          live={false}
          isMeasurementActive={undefined}
          isLabelActive
          labelContainer={labelContainer}
          unitOfMeasure={unitOfMeasure}
          onClick={() => {}}
          dashed
          labelsPointerEvents="none"
          isLabelVisible={false}
        />
      )}
      {polygonPoints && polygonPoints.length >= 2 && currentPoint && (
        <TwoPointMeasureSegment
          visible
          start={currentPoint}
          end={polygonPoints[polygonPoints.length - 1]}
          labelPosition={currentPoint}
          length={currentPoint.distanceTo(
            polygonPoints[polygonPoints.length - 1],
          )}
          index={0}
          main
          live={false}
          isMeasurementActive={undefined}
          isLabelActive
          labelContainer={labelContainer}
          unitOfMeasure={unitOfMeasure}
          onClick={() => {}}
          dashed
          labelsPointerEvents="none"
          isLabelVisible={false}
        />
      )}
      {polygonPoints && (
        <MultiPointRenderer
          points={polygonPoints}
          live
          isClosed={false}
          isLabelActive={false}
          visible
          unitOfMeasure={unitOfMeasure}
          onToggleUnitOfMeasure={() => {}}
          onHandlerClicked={onHandlerClicked}
          showActionBar={false}
          isMeasurementActive={undefined}
          labelContainer={labelContainer}
          labelsPointerEvents={undefined}
          isLabelVisible={false}
        />
      )}
      <MultiPointMeasureControls
        onPointsChanged={onPointsChanged}
        onCurrentPointChanged={onCurrentPointChanged}
        onMeasurementCompleted={onAnalysisCompleted}
        onDeleteActiveMeasurement={() => {}}
        onMeasurementStarted={onAnalysisStarted}
        onEscPressed={onAnalysisCanceled}
        onMeasurementCanceled={onAnalysisCanceled}
        ref={ref}
        actions={controlActions}
        active={analysisActive}
        projectOnShiftKey={ProjectionType.DoNotProject}
      />
    </>
  );
});

/**
 * Adjust the polygon selection points to be coplanar using RANSAC fit plane from the selected points
 *
 * @param points Polygon points to be adjusted
 * @param pointCloud The point cloud object
 * @returns Adjusted polygon points
 */
async function adjustPointsToBeCoplanar(
  points: Vector3[] | undefined,
  pointCloud: PointCloudObject,
): Promise<Vector3[] | undefined> {
  // no adjustment needed if less than 3 points
  if (!points || points.length < 3) return points;

  // Do a rough selection of points by the polygon
  //  - use a big plane threshold (1 meter) to cover more area
  //  - limit the number of points to 50k
  //  - limit the point density to 5mm
  const selection = await selectPointsByPolygon(pointCloud, {
    polygon: points,
    planeThreshold: 1.0,
    maxNumberOfPoints: 50_000,
    minPointDensity: 5,
  });
  if (!selection) return points;

  const lotvMath = await getLotvMath();
  // A much smaller RANSAC threshold is used to fit plane to the selected points
  // +/-5mm is a reasonable threshold for a flat surface, roughly 2 times the point density
  const PLANE_RANSAC_THRESHOLD = 0.005;
  const fitResult = lotvMath.fitPlaneRansac(
    selection.points,
    PLANE_RANSAC_THRESHOLD,
    true,
  );
  if (!fitResult) return points;

  const refPlane = new Plane().setFromNormalAndCoplanarPoint(
    new Vector3(fitResult.normal.x, fitResult.normal.y, fitResult.normal.z),
    new Vector3(
      fitResult.point.x + selection.origin.x,
      fitResult.point.y + selection.origin.y,
      fitResult.point.z + selection.origin.z,
    ),
  );

  return points.map((p) => refPlane.projectPoint(p, new Vector3()));
}
